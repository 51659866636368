import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { format, isAfter } from 'date-fns';
import { stringifyDateToISO8601 } from '../../../../util/dates';
import css from './DateRangerPicker.module.css';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../../../util/urlHelpers';
import { isArrayLength } from '../../../../util/genericHelpers';
import moment from 'moment';

const DateRangePickerComponent = props => {
  const { isLandingDateRange, onDateRangeChange } = props;
  const location = useLocation();
  const search = location?.search ? parse(location.search) : null;
  const [dateRange, setDateRange] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    if (search) {
      const parsedDates = search ? search?.dates && search?.dates.split(',') : null;
      const parsedStartDate = search && isArrayLength(parsedDates) ? moment(parsedDates[0]) : null;
      const parsedEndDate = search && isArrayLength(parsedDates) ? moment(parsedDates[1]) : null;
      setDateRange({ startDate: parsedStartDate, endDate: parsedEndDate });
    }
  }, []);

  const formatValue = dates => {
    const { startDate, endDate } = dates || {};
    const start = startDate && stringifyDateToISO8601(startDate);
    const end = endDate && stringifyDateToISO8601(endDate);
    return start && end ? `${start},${end}` : null;
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
    onDateRangeChange(formatValue({ startDate, endDate }));
  };

  const today = new Date();
  const todayFormatted = format(today, 'MM/dd/yyyy');
  const orientation =
    typeof window !== 'undefined' && window.matchMedia('(max-width: 1023px)').matches
      ? 'vertical'
      : 'horizontal';

  return (
    <div
      className={classNames({
        [css.landingDateRange]: isLandingDateRange,
      })}
    >
      <DateRangePicker
        startDate={dateRange ? dateRange.startDate : null}
        endDate={dateRange ? dateRange.endDate : null}
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        // displayFormat={todayFormatted}
        isOutsideRange={day => isAfter(day, today)}
        showClearDates
        startDateId="start_date"
        endDateId="end_date"
        startDatePlaceholderText={'Check-in'}
        endDatePlaceholderText={'Check-out'}
        orientation={orientation}
        hideKeyboardShortcutsPanel
      />
    </div>
  );
};

export default DateRangePickerComponent;
