import React from 'react';

import css from './SectionAuthHero.module.css';

import { AuthenticationOrConfirmInfoForm } from './AuthenticationPage';
import EmailVerificationInfo from './EmailVerificationInfo';

function SectionAuthHero(props) {
  const {
    history,
    name,
    email,
    onResendVerificationEmail,
    sendVerificationEmailInProgress,
    tab,
    authInfo,
    from,
    showFacebookLogin,
    showGoogleLogin,
    submitLogin,
    submitSignup,
    submitSingupWithIdp,
    authInProgress,
    loginError,
    signupError,
    confirmError,
    termsAndConditions,
    showEmailVerification,
    resendErrorMessage,
  } = props;

  return (
    <div className={css.heroContainer}>
      <div className={css.contentWidth}>
        <div className={css.heroContent}>
          <h1 className={css.title}>{tab != 'user' ? 'Become a Host' : 'Become a User'} </h1>
          <p className={css.sectionSubHeading}>
            {tab != 'user' ? 'Start To Earn Money On Your Schedule' : null}
          </p>
        </div>
        <div className={css.heroForm}>
          {showEmailVerification ? (
            <EmailVerificationInfo
              name={name}
              email={email}
              onResendVerificationEmail={onResendVerificationEmail}
              resendErrorMessage={resendErrorMessage}
              sendVerificationEmailInProgress={sendVerificationEmailInProgress}
            />
          ) : (
            <AuthenticationOrConfirmInfoForm
              tab={tab}
              authInfo={authInfo}
              from={from}
              showFacebookLogin={showFacebookLogin}
              showGoogleLogin={showGoogleLogin}
              submitLogin={submitLogin}
              submitSignup={submitSignup}
              submitSingupWithIdp={submitSingupWithIdp}
              authInProgress={authInProgress}
              loginError={loginError}
              signupError={signupError}
              confirmError={confirmError}
              termsAndConditions={termsAndConditions}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SectionAuthHero;
