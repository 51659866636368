import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconMinusCircle = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0002 3.3335C10.8168 3.3335 3.3335 10.8168 3.3335 20.0002C3.3335 29.1835 10.8168 36.6668 20.0002 36.6668C29.1835 36.6668 36.6668 29.1835 36.6668 20.0002C36.6668 10.8168 29.1835 3.3335 20.0002 3.3335ZM26.5335 21.2502H13.2002C12.5168 21.2502 11.9502 20.6835 11.9502 20.0002C11.9502 19.3168 12.5168 18.7502 13.2002 18.7502H26.5335C27.2168 18.7502 27.7835 19.3168 27.7835 20.0002C27.7835 20.6835 27.2335 21.2502 26.5335 21.2502Z"
        fill="black"
      />
    </svg>
  );
};

IconMinusCircle.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMinusCircle.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMinusCircle;
