import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from '../../../containers/AuthenticationPage/LoginForm/LoginForm';
import { authenticationInProgress, login } from '../../../ducks/auth.duck';

import css from './AuthWrapper.module.css';

function AuthWrapper() {
  const dispatch = useDispatch();
  const submitLogin = params => {
    const { email, password } = params;
    return dispatch(login(email, password));
  };

  const authInProgress = useSelector(authenticationInProgress);

  return (
    <div>
      <LoginForm className={css.loginForm} onSubmit={submitLogin} inProgress={authInProgress} />
    </div>
  );
}

export default AuthWrapper;
