import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl';
import {
  Button,
  Form,
  IconSearch,
  LocationAutocompleteInput,
  NamedLink,
  OutsideClickHandler,
} from '../../../components';

import css from './LandingSearchForm.module.css';
import DateRangePickerComponent from './DateRangePicker/DateRangerPicker';
import Guests from './Guests/Guests';
import IconCalender from '../../../components/IconCalender/IconCalender';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function generateDescription(adults, children, pets) {
  let description = '';

  const addCommaIfNeeded = () => {
    if (description) {
      description += ', ';
    }
  };

  if (adults && adults > 0) {
    const adultText = adults > 1 ? 'Adults' : 'Adult';
    description += `${adults} ${adultText}`;
    addCommaIfNeeded();
  }

  if (children && children > 0) {
    const childText = children > 1 ? 'Children' : 'Child';
    description += `${children} ${childText}`;
    addCommaIfNeeded();
  }

  if (pets && pets > 0) {
    const petText = pets > 1 ? 'Pets' : 'Pet';
    description += `${pets} ${petText}`;
    addCommaIfNeeded();
  }

  return description;
}

const identity = v => v;

const LocationSearchField = props => {
  const { intl, handleChange, isLandingLocation, isSearchPageFilter, isLandingPage } = props;
  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const searchOnChange = value => {
          onChange(value);
          handleChange(value);
        };

        const searchInput = { ...restInput, onChange: searchOnChange };
        return (
          <LocationAutocompleteInput
            placeholder={intl.formatMessage({ id: 'LandingSearchForm.placeholder' })}
            iconClassName={css.searchInputIcon}
            inputClassName={css.searchInput}
            predictionsClassName={css.searchPredictions}
            input={searchInput}
            meta={meta}
            rootClassName={isLandingLocation && css.landingLocationSearch}
            isLandingLocation={isLandingLocation}
            isSearchPageFilter={isSearchPageFilter}
          />
        );
      }}
    />
  );
};

const LandingSearchFormComponent = props => {
  const handleChange = location => {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      props.onSubmit({ location });
    }
  };

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          values,
          intl,
          form,
          viewport,
          isSearchPageFilter,
          isLandingPage,
        } = formRenderProps;
        const isMobileLayout = viewport && viewport?.width < 1024;
        const location = useLocation();
        const search = location?.search;

        const classes = classNames(rootClassName || css.root, className, {
          [css.searchPageFilter]: isSearchPageFilter,
        });

        const [showGuests, setGuests] = useState(false);
        const description = generateDescription(values?.adults, values?.children, values?.pets);

        return (
          <Form className={classes} onSubmit={e => e.preventDefault()}>
            <LocationSearchField
              intl={intl}
              handleChange={location => {
                if (location.selectedPlace) {
                  form.change('location', location);
                }
              }}
              isLandingLocation={true}
              isSearchPageFilter={isSearchPageFilter}
            />
            <div className={css.travelDateSection}>
              <DateRangePickerComponent
                isLandingDateRange={true}
                isSearchPageFilter={isSearchPageFilter}
                onDateRangeChange={dates => form.change('dates', dates)}
              />
            </div>
            <OutsideClickHandler
              className={css.guestsWrapper}
              onOutsideClick={() => setGuests(false)}
            >
              <span onClick={() => setGuests(!showGuests)} className={css.guestsBtn}>
                <IconCalender /> {description || 'Guests'}
              </span>
              {showGuests ? (
                <Guests
                  onClose={() => setGuests(false)}
                  onApply={values => {
                    for (const key in values) {
                      if (key === 'pets') {
                        form.change('pub_petsAllowed', 'yes');
                      }
                    }
                    const adults = values.adults || 0;
                    const children = values.children || 0;

                    const maxPeople = adults + children;
                    form.change('pub_maxPeople', `${maxPeople},100`);
                  }}
                />
              ) : null}
            </OutsideClickHandler>
            {search?.length > 1 && !isMobileLayout ? (
              <NamedLink className={css.clearFilter} name="LandingPage">
                &times;
              </NamedLink>
            ) : null}
            <div className={classNames(isLandingPage ? css.fullWidthBtn : css.footerBtn)}>
              {isMobileLayout && (
                <NamedLink className={css.clearFilter} name="LandingPage">
                  &times;
                </NamedLink>
              )}
              <Button
                className={classNames({
                  [css.searchIcon]: isSearchPageFilter,
                  [css.searchBtn]: !isSearchPageFilter,
                })}
                onClick={() => props.onSubmit(values)}
                type="submit"
              >
                {isLandingPage ? (
                  <span className={css.landingSearchBtn}>
                    <IconSearch /> Search
                  </span>
                ) : (
                  <IconSearch />
                )}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

LandingSearchFormComponent.defaultProps = { rootClassName: null, className: null };

LandingSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LandingSearchForm = injectIntl(LandingSearchFormComponent);

export default LandingSearchForm;
