import React, { useState } from 'react';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';

import css from './DashboardSidebar.module.css';
import Icons from '../Icons/Icons';
import { useHistory, useLocation } from 'react-router-dom';

const SideLink = ({ iconName, linkText, onClick, isOffers = null }) => {
  return (
    <div className={css.sidelink} onClick={onClick}>
      <span className={classNames(css.sideNavIcon, css.shopIcon)}>
        <Icons name={iconName} />
      </span>{' '}
      {linkText}
    </div>
  );
};

function DashboardSidebar() {
  const history = useHistory();
  const location = useLocation();
  const pathname = location && location.pathname;

  const redirectToPage = (name, params = {}) => {
    history.push(createResourceLocatorString(name, routeConfiguration(), params, {}));
  };

  return (
    <div className={css.accordionMenu}>
      <SideLink
        iconName="dashboardHome"
        linkText="Home"
        onClick={() => redirectToPage('LandingPage')}
      />
      <SideLink
        iconName="dashboardInbox"
        linkText="Orders"
        onClick={() => redirectToPage('InboxPage', { tab: 'sales' })}
      />
      <SideLink
        iconName="listings"
        linkText="Your listings"
        onClick={() => redirectToPage('ManageListingsPage')}
      />
      <SideLink
        iconName="profile"
        linkText=" Profile Settings"
        onClick={() => redirectToPage('ProfileSettingsPage')}
      />
      <SideLink
        iconName="contact"
        linkText="Contact details"
        onClick={() => redirectToPage('ContactDetailsPage')}
      />
      <SideLink
        iconName="password"
        linkText="Password"
        onClick={() => redirectToPage('PasswordChangePage')}
      />
      <SideLink
        iconName="payout"
        linkText="Payout details"
        onClick={() => redirectToPage('StripePayoutPage')}
      />
      <SideLink
        iconName="card"
        linkText="Payment methods"
        onClick={() => redirectToPage('PaymentMethodsPage')}
      />
    </div>
  );
}

export default DashboardSidebar;
