import React from 'react';
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import { Icons, Modal, Toastify } from '../../components';
import { copyToClipboard } from '../../util/genericHelpers';
import { toast } from 'react-toastify';
import { useConfiguration } from '../../context/configurationContext';

import css from './SocialSharing.module.css';

function SocialSharing(props) {
  const {
    onManageDisableScrolling,
    onOpenShareModal,
    isSocialSharingModalOpen,
    location,
    listing,
  } = props;

  const config = useConfiguration();
  const currentURL = config.marketplaceRootURL + location.pathname + location.search;
  const listingTitle = listing?.attributes?.title;

  const sharingModal = (
    <Modal
      usePortal
      id="ListingPageSharingModal"
      isOpen={isSocialSharingModalOpen}
      onClose={() => onOpenShareModal(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      modalTitle={`Share ${listingTitle}`}
      isGallaryModal={true}
    >
      <div className={css.shareContainer}>
        <ul className={css.socialMedialinks}>
          <li>
            <FacebookShareButton url={currentURL} quote={listingTitle}>
              <span className={css.leftIcon}>
                <Icons name="fbIcon" />
                Facebook
              </span>
            </FacebookShareButton>
          </li>
          <li>
            <FacebookMessengerShareButton
              url={currentURL}
              quote={listingTitle}
              appId="2943206489158222"
            >
              <span className={css.leftIcon}>
                <Icons name="messengerIcon" />
                Messenger
              </span>
            </FacebookMessengerShareButton>
          </li>
          <li>
            <TwitterShareButton title={listingTitle} url={currentURL}>
              <Icons name="twitterIcon" />
              Twitter
            </TwitterShareButton>
          </li>
          <li>
            <EmailShareButton
              url={currentURL}
              subject={listingTitle}
              body="Please checkout this property"
            >
              <Icons name="emailIcon" />
              Email
            </EmailShareButton>
          </li>
          <li
            onClick={() => {
              copyToClipboard(currentURL);
              toast.success(`Copied to clipboard`, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            }}
          >
            <Icons name="copyIcon" />
            Copy link
          </li>
        </ul>
        <Toastify />
      </div>
    </Modal>
  );

  return <div>{sharingModal}</div>;
}

export default SocialSharing;
