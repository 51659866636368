import React from 'react';
import css from './AuthenticationPage.module.css';

function SectionListPlaces() {
  return (
    <div className={css.findComfortableplace}>
      <div className={css.amazingPlacesSection}>
        <div className={css.bannerContent}>
          <h2>List Your Space</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vitae pretium dolor, et
            facilisis lacus. Duis ultricies egestas vulputate
          </p>
          <button className={css.bookNowBtn}>become a host</button>
        </div>
      </div>
    </div>
  );
}

export default SectionListPlaces;
