import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconBedroom = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 22.7499H4.07997C2.50997 22.7499 1.21997 21.4899 1.21997 19.9299V5.08994C1.21997 3.55994 1.78997 2.36994 2.82997 1.71994C3.87997 1.05994 5.23997 1.07994 6.64997 1.77994L11.09 3.95994C12.3 4.55994 13.25 6.06994 13.25 7.40994V21.9999C13.25 22.4099 12.91 22.7499 12.5 22.7499ZM4.55997 2.74994C4.20997 2.74994 3.88997 2.82994 3.61997 2.99994C3.03997 3.35994 2.71997 4.10994 2.71997 5.08994V19.9299C2.71997 20.6599 3.32997 21.2499 4.07997 21.2499H11.75V7.40994C11.75 6.63994 11.13 5.64994 10.42 5.30994L5.97997 3.12994C5.46997 2.87994 4.98997 2.74994 4.55997 2.74994Z"
        fill="#3e5ba6"
      />
      <path
        d="M18.81 22.75H12.5C12.09 22.75 11.75 22.41 11.75 22V10.42C11.75 10.19 11.85 9.98002 12.03 9.84002C12.21 9.70002 12.43 9.64002 12.66 9.69002L13.13 9.79002L19.67 11.25C21.66 11.69 22.66 12.86 22.72 14.85C22.73 14.85 22.73 14.95 22.73 15.06V18.84C22.72 21.4 21.37 22.75 18.81 22.75ZM13.25 21.25H18.81C20.57 21.25 21.22 20.6 21.22 18.84V14.98C21.17 13.61 20.65 13 19.34 12.71L13.25 11.35V21.25Z"
        fill="#3e5ba6"
      />
      <path
        d="M8.97 9.75H5.5C5.09 9.75 4.75 9.41 4.75 9C4.75 8.59 5.09 8.25 5.5 8.25H8.97C9.38 8.25 9.72 8.59 9.72 9C9.72 9.41 9.39 9.75 8.97 9.75Z"
        fill="#3e5ba6"
      />
      <path
        d="M8.97 13.75H5.5C5.09 13.75 4.75 13.41 4.75 13C4.75 12.59 5.09 12.25 5.5 12.25H8.97C9.38 12.25 9.72 12.59 9.72 13C9.72 13.41 9.39 13.75 8.97 13.75Z"
        fill="#3e5ba6"
      />
      <path
        d="M15.22 17.75C13.57 17.75 12.22 16.4 12.22 14.75V10.52C12.22 10.29 12.32 10.08 12.5 9.92998C12.68 9.77998 12.91 9.72998 13.13 9.77998L17.63 10.79C17.97 10.87 18.22 11.17 18.22 11.52V14.74C18.22 16.4 16.88 17.75 15.22 17.75ZM13.72 11.46V14.75C13.72 15.58 14.39 16.25 15.22 16.25C16.05 16.25 16.72 15.58 16.72 14.75V12.13L13.72 11.46Z"
        fill="#3e5ba6"
      />
      <path
        d="M19.72 17.75C18.07 17.75 16.72 16.4 16.72 14.75V11.53C16.72 11.3 16.82 11.09 17 10.95C17.18 10.81 17.41 10.75 17.63 10.8L19.66 11.25C21.65 11.69 22.65 12.86 22.71 14.85C22.71 14.87 22.71 14.89 22.71 14.91C22.63 16.5 21.32 17.75 19.72 17.75ZM18.22 12.46V14.75C18.22 15.58 18.89 16.25 19.72 16.25C20.51 16.25 21.16 15.64 21.21 14.86C21.16 13.6 20.64 13 19.34 12.71L18.22 12.46Z"
        fill="#3e5ba6"
      />
    </svg>
  );
};

IconBedroom.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBedroom.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBedroom;
