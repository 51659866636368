import React from 'react';
import SignupHostForm from '../SignupHostForm/SignupHostForm';

function SectionHost(props) {
  const { rootClassName, onSubmit, inProgress, termsAndConditions } = props;
  return (
    <SignupHostForm
      className={rootClassName}
      onSubmit={onSubmit}
      inProgress={inProgress}
      termsAndConditions={termsAndConditions}
    />
  );
}

export default SectionHost;
