import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

import css from './IconCalender.module.css';

const IconCalender = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 5.75C7.59 5.75 7.25 5.41 7.25 5V2C7.25 1.59 7.59 1.25 8 1.25C8.41 1.25 8.75 1.59 8.75 2V5C8.75 5.41 8.41 5.75 8 5.75Z"
        fill="#292D32"
      />
      <path
        d="M16 5.75C15.59 5.75 15.25 5.41 15.25 5V2C15.25 1.59 15.59 1.25 16 1.25C16.41 1.25 16.75 1.59 16.75 2V5C16.75 5.41 16.41 5.75 16 5.75Z"
        fill="#292D32"
      />
      <path
        d="M8.5 14.5C8.24 14.5 7.97999 14.39 7.78999 14.21C7.74999 14.16 7.7 14.11 7.67 14.06C7.63 14 7.6 13.94 7.58 13.88C7.55 13.82 7.53 13.76 7.52 13.7C7.51 13.63 7.5 13.56 7.5 13.5C7.5 13.24 7.60999 12.98 7.78999 12.79C8.15999 12.42 8.83001 12.42 9.21001 12.79C9.39001 12.98 9.5 13.24 9.5 13.5C9.5 13.56 9.49 13.63 9.48 13.7C9.47 13.76 9.45 13.82 9.42 13.88C9.4 13.94 9.37 14 9.33 14.06C9.29 14.11 9.25001 14.16 9.21001 14.21C9.02001 14.39 8.76 14.5 8.5 14.5Z"
        fill="#292D32"
      />
      <path
        d="M12 14.5C11.87 14.5 11.74 14.47 11.62 14.42C11.49 14.37 11.38 14.3 11.29 14.21C11.25 14.16 11.2 14.11 11.17 14.06C11.13 14 11.1 13.94 11.08 13.88C11.05 13.82 11.03 13.76 11.02 13.7C11.01 13.63 11 13.56 11 13.5C11 13.24 11.11 12.98 11.29 12.79C11.38 12.7 11.49 12.63 11.62 12.58C11.98 12.42 12.43 12.51 12.71 12.79C12.89 12.98 13 13.24 13 13.5C13 13.56 12.99 13.63 12.98 13.7C12.97 13.76 12.95 13.82 12.92 13.88C12.9 13.94 12.87 14 12.83 14.06C12.79 14.11 12.75 14.16 12.71 14.21C12.52 14.39 12.26 14.5 12 14.5Z"
        fill="#292D32"
      />
      <path
        d="M8.5 18C8.23 18 7.97999 17.89 7.78999 17.71C7.60999 17.52 7.5 17.26 7.5 17C7.5 16.74 7.60999 16.48 7.78999 16.29C7.83999 16.25 7.89 16.2 7.94 16.17C8 16.13 8.06 16.1 8.12 16.08C8.18 16.05 8.24 16.03 8.3 16.02C8.5 15.98 8.7 16 8.88 16.08C9.01 16.13 9.11001 16.2 9.21001 16.29C9.39001 16.48 9.5 16.74 9.5 17C9.5 17.26 9.39001 17.52 9.21001 17.71C9.11001 17.8 9.01 17.87 8.88 17.92C8.76 17.97 8.63 18 8.5 18Z"
        fill="#292D32"
      />
      <path
        d="M20.5 9.83997H3.5C3.09 9.83997 2.75 9.49997 2.75 9.08997C2.75 8.67997 3.09 8.33997 3.5 8.33997H20.5C20.91 8.33997 21.25 8.67997 21.25 9.08997C21.25 9.49997 20.91 9.83997 20.5 9.83997Z"
        fill="#292D32"
      />
      <path
        d="M18 23.75C15.38 23.75 13.25 21.62 13.25 19C13.25 16.38 15.38 14.25 18 14.25C20.62 14.25 22.75 16.38 22.75 19C22.75 21.62 20.62 23.75 18 23.75ZM18 15.75C16.21 15.75 14.75 17.21 14.75 19C14.75 20.79 16.21 22.25 18 22.25C19.79 22.25 21.25 20.79 21.25 19C21.25 17.21 19.79 15.75 18 15.75Z"
        fill="#292D32"
      />
      <path
        d="M19.0699 20.86C18.8799 20.86 18.6899 20.79 18.5399 20.64L16.4299 18.53C16.1399 18.24 16.1399 17.76 16.4299 17.47C16.7199 17.18 17.1999 17.18 17.4899 17.47L19.5999 19.58C19.8899 19.87 19.8899 20.35 19.5999 20.64C19.4499 20.79 19.2599 20.86 19.0699 20.86Z"
        fill="#292D32"
      />
      <path
        d="M16.9301 20.89C16.7401 20.89 16.5501 20.82 16.4001 20.67C16.1101 20.38 16.1101 19.9 16.4001 19.61L18.5101 17.5C18.8001 17.21 19.2801 17.21 19.5701 17.5C19.8601 17.79 19.8601 18.27 19.5701 18.56L17.4601 20.67C17.3101 20.81 17.1201 20.89 16.9301 20.89Z"
        fill="#292D32"
      />
      <path
        d="M15.37 22.75H8C4.35 22.75 2.25 20.65 2.25 17V8.5C2.25 4.85 4.35 2.75 8 2.75H16C19.65 2.75 21.75 4.85 21.75 8.5V16.36C21.75 16.67 21.56 16.95 21.26 17.06C20.97 17.17 20.64 17.09 20.43 16.85C19.81 16.15 18.92 15.75 17.99 15.75C16.2 15.75 14.74 17.21 14.74 19C14.74 19.59 14.9 20.17 15.21 20.67C15.38 20.97 15.6 21.22 15.84 21.43C16.08 21.63 16.17 21.96 16.06 22.26C15.97 22.55 15.69 22.75 15.37 22.75ZM8 4.25C5.14 4.25 3.75 5.64 3.75 8.5V17C3.75 19.86 5.14 21.25 8 21.25H13.82C13.45 20.57 13.25 19.8 13.25 19C13.25 16.38 15.38 14.25 18 14.25C18.79 14.25 19.57 14.45 20.25 14.82V8.5C20.25 5.64 18.86 4.25 16 4.25H8Z"
        fill="#292D32"
      />
    </svg>
  );
};

IconCalender.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCalender.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCalender;
