import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { getBookableDates, getWeekendAmount } from '../../helpers/dataExtractors';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTimeSlots,
  getMonthlySlotsSelectors,
} from '../../containers/ListingPage/ListingPage.duck';
import { isArrayLength } from '../../util/genericHelpers';
import {
  fetchAllExceptionsSelector,
  requestFetchAvailabilityExceptions,
} from '../../containers/EditListingPage/EditListingPage.duck';
import css from './Calendar.module.css';

export function extractNumberFromDuration(durationString) {
  if (durationString) {
    // Use regular expression to extract the numeric part
    const numericPart = durationString.match(/\d+/);

    // Convert the extracted numeric part to an integer
    if (numericPart) {
      return parseInt(numericPart[0]);
    }
    return null;
  }

  // Return a default value or handle the case where no numeric part is found
  return null;
}

function DateInput(props) {
  const {
    // isDayBlocked,
    // isOutsideRange,
    focusedInput,
    onFocusChange,
    startDate,
    endDate,
    minimumNights,
    onDatesChange,
    startDatePlaceholderText,
    endDatePlaceholderText,
    screenReaderInputMessage,
    listing,
    datePickerProps,
    initialStartMoment,
    initialEndMoment,
  } = props;

  const dispatch = useDispatch();
  const [minAllowedDates, setMinAllowedDate] = useState(1);

  const bookableDates = getBookableDates(listing);
  const publicData = listing?.attributes?.publicData;
  const minimumStay = publicData?.mimimumStay;
  const maximumStay = publicData?.maximumStay;
  const howFar = publicData?.howFar;
  const daysBefore = publicData?.daysBefore;
  const listingTz = listing?.attributes?.availabilityPlan?.timezone;

  const howFarValue = extractNumberFromDuration(howFar);
  const weekendAmount = getWeekendAmount(listing) || listing?.attributes?.price?.amount / 100;

  const slots = useSelector(getMonthlySlotsSelectors);
  const allExceptions = useSelector(fetchAllExceptionsSelector);

  const flattenTimeSlots = inputObj => {
    return Object.values(inputObj).reduce((acc, obj) => {
      return acc.concat(obj.timeSlots);
    }, []);
  };

  const handleDatesChange = dateProps => {
    const { startDate, endDate } = dateProps;
    onDatesChange(dateProps);
    dispatch(
      fetchTimeSlots(
        listing?.id,
        startDate,
        moment(endDate)
          .add(88, 'days')
          .toDate(),
        listingTz
      )
    );
  };

  const flattenedTimeSlots = slots && flattenTimeSlots(slots);

  const calculatePrice = day => {
    const dayOfWeek = day.day(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    // Define your pricing logic here
    if (dayOfWeek >= 1 && dayOfWeek <= 4) {
      // Weekday price (Mon - Thu)
      return '$' + listing?.attributes?.price?.amount / 100;
    } else if (dayOfWeek === 5 || dayOfWeek === 6) {
      // Weekend price (Fri - Sat)
      return '$' + weekendAmount;
    } else {
      // Default price for Sunday
      return '$' + weekendAmount;
    }
  };

  const isDayBlocked = day => {
    if (isArrayLength(flattenedTimeSlots) || isArrayLength(allExceptions)) {
      const dayMoment = moment(day);

      // Check if the day is within any of the slot date ranges
      const isBlockedBySlots = !flattenedTimeSlots?.some(slot => {
        const slotStart = moment(slot?.attributes?.start);
        const slotEnd = moment(slot?.attributes?.end);
        return dayMoment.isBetween(slotStart, slotEnd, 'day', '()');
      });

      // Check if the day is within any of the exception date ranges
      const isBlockedByExceptions = allExceptions?.some(exception => {
        const exceptionStart = moment(exception?.attributes?.start);
        const exceptionEnd = moment(exception?.attributes?.end);
        return dayMoment.isBetween(exceptionStart, exceptionEnd, 'day', '()');
      });

      // Check if the day is outside the minimumStay and maximumStay range
      const isOutsideMinMaxRange =
        dayMoment.diff(startDate, 'days') < minimumStay ||
        dayMoment.diff(startDate, 'days') > maximumStay;

      const isMinSelectedAndMaxNotSelected = Number(minimumStay) > Number(maximumStay);

      if ((minimumStay == 0 && maximumStay == 0) || isMinSelectedAndMaxNotSelected) {
        if (isMinSelectedAndMaxNotSelected && minAllowedDates == 1) {
          setMinAllowedDate(minimumStay);
        }
        return isBlockedBySlots || isBlockedByExceptions;
      } else {
        return isBlockedBySlots || isBlockedByExceptions || isOutsideMinMaxRange;
      }
    }
  };

  const isOutsideRange = day => {
    if (isArrayLength(flattenedTimeSlots)) {
      const dayMoment = moment(day);

      const maxAllowedDate = moment()
        .clone()
        .add(howFarValue, 'months');
      let minAllowedDate = moment().clone();

      if (daysBefore == '1 day') {
        minAllowedDate = minAllowedDate.add(1, 'day');
      } else if (daysBefore == '3 days') {
        minAllowedDate = minAllowedDate.add(3, 'days');
      } else if (daysBefore == '5 days') {
        minAllowedDate = minAllowedDate.add(5, 'days');
      } else if (daysBefore == '1 week') {
        minAllowedDate = minAllowedDate.add(7, 'days');
      } else if (daysBefore == '2 weeks') {
        minAllowedDate = minAllowedDate.add(14, 'days');
      } else {
        minAllowedDate = minAllowedDate.add(30, 'days');
      }

      // Check if the day is before the earliest available date or after the latest available date
      return dayMoment.isAfter(maxAllowedDate, 'day');
    }
  };

  const renderCalendarInfo = () => (
    <div className={css.renderCalendarInfo}>
      <div className={css.minimumStay}>
        {Number(minimumStay) > 0 ? `Minimum Stay : ${minimumStay} Days` : null}
      </div>
      <div className={css.calendarActionButtons}>
        <span
          onClick={() => {
            onFocusChange('startDate');
            onDatesChange({ startDate: initialStartMoment, endDate: initialEndMoment });
          }}
        >
          Clear Dates
        </span>
        <button onClick={() => onFocusChange(null)}>Close</button>
      </div>
    </div>
  );

  return (
    <>
      <DateRangePicker
        {...datePickerProps}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        startDate={startDate}
        endDate={endDate}
        minimumNights={minAllowedDates}
        onDatesChange={handleDatesChange}
        startDatePlaceholderText={startDatePlaceholderText}
        endDatePlaceholderText={endDatePlaceholderText}
        screenReaderInputMessage={screenReaderInputMessage}
        renderCalendarInfo={renderCalendarInfo}
      />
    </>
  );
}

export default DateInput;
