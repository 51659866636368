import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ExternalLink,
  Icons,
  Modal,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import flagStyle from './Flag.module.css';
import { useMyContext } from '../../../custom-contexts/StateHolder';
import { getUserType } from '../../../helpers/dataExtractors';
import CountryList from '../CountryList/CountryList';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    isHeaderSticky,
    isListingPage,
    isWhiteBgNav,
    onManageDisableScrolling,
    isCountriesModalOpen,
    setCountriesModalOpen,
    stripeCountriesModal,
  } = props;

  const history = useHistory();
  const [isModalOpen, setModalOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const { setLoginModalToggle } = useMyContext();

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(
    rootClassName || css.root,
    className,
    isHeaderSticky ? css.headerSticky : null,
    isWhiteBgNav ? css.whiteBgNav : null,
    isListingPage ? css.listingPageNav : null
  );

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink className={css.navLink} name="InboxPage" params={{ tab: 'orders' }}>
      <FormattedMessage id="TopbarDesktop.inbox" />
      {/* {notificationDot} */}
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton
            rootClassName={css.logoutButton}
            onClick={() => {
              setLoginModalToggle(false);
              onLogout();
            }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupUserPage" className={css.navLink}>
      <FormattedMessage id="TopbarDesktop.signup" />
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <span name="LoginPage" className={css.navLink} onClick={() => setLoginModalToggle(true)}>
      <FormattedMessage id="TopbarDesktop.login" />
    </span>
  );

  const homeLink = (
    <NamedLink name="LandingPage" className={css.navLink}>
      <FormattedMessage id="TopbarDesktop.home" />
    </NamedLink>
  );

  const discoverLink = (
    <NamedLink name="SearchPage" className={css.navLink}>
      <FormattedMessage id="TopbarDesktop.search" />
    </NamedLink>
  );

  const aboutLink = (
    <NamedLink className={css.navLink} name="AboutPage">
      <FormattedMessage id="TopbarDesktop.about" />
    </NamedLink>
  );

  const contactLink = (
    <NamedLink className={css.navLink} name="ContactUsPage">
      <FormattedMessage id="TopbarDesktop.contactUs" />
    </NamedLink>
  );

  const role = getUserType(currentUser);
  const becomeHostLink = !isAuthenticated ? (
    <NamedLink className={css.becomeHostBtn} name="SignupPage">
      <FormattedMessage id="TopbarDesktop.createListing" />
    </NamedLink>
  ) : null;

  const addPropertyLink = isAuthenticated && (
    <NamedLink className={css.navLink} name="NewListingPage">
      <FormattedMessage id="TopbarDesktop.addProperty" />
    </NamedLink>
  );

  const favouritesLink = isAuthenticated && (
    <NamedLink className={css.navLink} name="FavouritesPage">
      <FormattedMessage id="TopbarDesktop.favouritesPage" />
    </NamedLink>
  );

  const dashboardLink = isAuthenticated && (
    <NamedLink className={css.navLink} name="DashboardPage">
      <FormattedMessage id="TopbarDesktop.DashboardPage" />
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.leftNavigation}>
        <LinkedLogo
          className={css.logoLink}
          format="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        />
        <div className={css.leftSection}>
          {isMobile ? homeLink : null}
          <span className={css.countryLabel}>Company</span>
          <select
            onChange={e => {
              const key = e.target.value;
              if (typeof window !== 'undefined') {
                window.location.href = `https://www.bnbunite.com/${key}`;
              }
            }}
          >
            <option value="" disabled>
              Company
            </option>
            <option value="about">About</option>
            <option value="monthly-cash-program">Rewards Program</option>
            <option value="scholarship">Scholarship Program</option>
            <option value="privacy-policy">Privacy Policy</option>
            <option value="terms-of-service">Terms of Service</option>
          </select>
          <div className={css.countryModal}>
            <span className={css.navLink} onClick={() => setCountriesModalOpen(true)}>
              Country List
            </span>
          </div>
        </div>
      </div>
      <div className={css.rightSection}>
        {addPropertyLink}
        {favouritesLink}
        {dashboardLink}
        {loginLink}
        {/* {signupLink} */}
        {becomeHostLink}
        {inboxLink}
        {profileMenu}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
