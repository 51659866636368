import React, { Component } from 'react';
import { array, node, string } from 'prop-types';
import classNames from 'classnames';

import css from './Track.module.css';

let towerData = [
  { faded: 20, height: '7px' },
  { faded: 40, height: '4px' },
  { faded: 60, height: '10px' },
  { faded: 80, height: '24px' },
  { faded: 100, height: '10px' },
  { faded: 120, height: '19px' },
  { faded: 140, height: '35px' },
  { faded: 160, height: '19px' },
  { faded: 180, height: '41px' },
  { faded: 200, height: '30px' },
  { faded: 220, height: '44px' },
  { faded: 240, height: '58px' },
  { faded: 260, height: '40px' },
  { faded: 280, height: '41px' },
  { faded: 300, height: '36px' },
  { faded: 320, height: '60px' },
  { faded: 340, height: '44px' },
  { faded: 360, height: '38px' },
  { faded: 380, height: '41px' },
  { faded: 400, height: '64px' },
  { faded: 420, height: '61px' },
  { faded: 440, height: '58px' },
  { faded: 460, height: '46px' },
  { faded: 480, height: '29px' },
  { faded: 500, height: '47px' },
  { faded: 520, height: '38px' },
  { faded: 540, height: '35px' },
  { faded: 560, height: '55px' },
  { faded: 580, height: '21px' },
  { faded: 600, height: '29px' },
  { faded: 620, height: '5px' },
  { faded: 640, height: '19px' },
  { faded: 660, height: '13px' },
  { faded: 680, height: '8px' },
  { faded: 700, height: '19px' },
  { faded: 720, height: '10px' },
  { faded: 740, height: '11px' },
  { faded: 760, height: '22px' },
  { faded: 780, height: '11px' },
  { faded: 800, height: '7px' },
  { faded: 820, height: '5px' },
  { faded: 840, height: '5px' },
  { faded: 860, height: '2px' },
  { faded: 880, height: '7px' },
  { faded: 900, height: '1px' },
  { faded: 920, height: '10px' },
  { faded: 940, height: '2px' },
  { faded: 960, height: '1px' },
  { faded: 980, height: '4px' },
  { faded: 1000, height: '7px' },
];

class Track extends Component {
  render() {
    const { rootClassName, className, children, handles, valueToPosition } = this.props;
    const positionFromIndex = index => valueToPosition(handles[index]);
    const classes = classNames(rootClassName || css.root, className);
    return (
      <div className={classes}>
        <div className={towerData && towerData.length == 0 ? css.track : ''} />
        <div className={css.towerMainDiv}>
          {/* aria-label="86% of stays are available in the price range you selected." role="img" */}
          {towerData && towerData.length
            ? towerData.map((e, i) => {
                let faded = false;
                if (handles[0] > e.faded || handles[1] < e.faded) {
                  faded = true;
                }
                return (
                  <div
                    key={i}
                    className={faded ? css.fadedTower : css.highlightedTower}
                    style={{ height: e.height }}
                  ></div>
                );
              })
            : null}
        </div>
        {handles.reduce((ranges, h, index) => {
          return index < handles.length - 1
            ? [
                ...ranges,
                <div
                  key={`range_${index}-${index + 1}`}
                  className={towerData && towerData.length == 0 ? css.range : ''}
                  style={{
                    left: `${valueToPosition(h)}px`,
                    width: `${positionFromIndex(index + 1) - valueToPosition(h)}px`,
                  }}
                />,
              ]
            : ranges;
        }, [])}

        {children}
      </div>
    );
  }
}

Track.defaultProps = {
  rootClassName: null,
  className: null,
  children: null,
  handles: [],
};

Track.propTypes = {
  rootClassName: string,
  className: string,
  children: node,
  handles: array,
};

export default Track;
