/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import {
  AvatarLarge,
  Icons,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../components';

import css from './TopbarMobileMenu.module.css';
import { useMyContext } from '../../../custom-contexts/StateHolder';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    onCloseDrawer,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const { setLoginModalToggle } = useMyContext();
  const location = useLocation();

  useEffect(() => {
    const isLogin = location?.pathname === '/login';
    if (isLogin) {
      setLoginModalToggle(true);
    }
  }, [location]);

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  if (!isAuthenticated) {
    const signupAsHost = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        Sign up as a host
      </NamedLink>
    );
    const signup = (
      <NamedLink name="SignupUserPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            Hello <br />
            <span>Would you like to:</span>
          </div>
          <div className={css.preloginBtns}>
            {signupAsHost}
            {signup}
          </div>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('AboutPage'))}
            name="AboutPage"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 18.6458V8.05426C4 5.20025 4 3.77325 4.87868 2.88663C5.75736 2 7.17157 2 10 2H14C16.8284 2 18.2426 2 19.1213 2.88663C20 3.77325 20 5.20025 20 8.05426V18.6458C20 20.1575 20 20.9133 19.538 21.2108C18.7831 21.6971 17.6161 20.6774 17.0291 20.3073C16.5441 20.0014 16.3017 19.8485 16.0325 19.8397C15.7417 19.8301 15.4949 19.9768 14.9709 20.3073L13.06 21.5124C12.5445 21.8374 12.2868 22 12 22C11.7132 22 11.4555 21.8374 10.94 21.5124L9.02913 20.3073C8.54415 20.0014 8.30166 19.8485 8.03253 19.8397C7.74172 19.8301 7.49493 19.9768 6.97087 20.3073C6.38395 20.6774 5.21687 21.6971 4.46195 21.2108C4 20.9133 4 20.1575 4 18.6458Z"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 11H8"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 7L8 7"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>{' '}
            <FormattedMessage id="AboutPage.title" />
          </NamedLink>
          {/* <NamedLink className={css.navigationLink} name="ContactUsPage">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 11.5667C22 16.8499 17.5222 21.1334 12 21.1334C11.3507 21.1343 10.7032 21.0742 10.0654 20.9545C9.60633 20.8682 9.37678 20.8251 9.21653 20.8496C9.05627 20.8741 8.82918 20.9948 8.37499 21.2364C7.09014 21.9197 5.59195 22.161 4.15111 21.893C4.69874 21.2194 5.07275 20.4112 5.23778 19.5448C5.33778 19.0148 5.09 18.5 4.71889 18.1231C3.03333 16.4115 2 14.1051 2 11.5667C2 6.28357 6.47778 2 12 2C17.5222 2 22 6.28357 22 11.5667Z"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M11.9955 12H12.0045M15.991 12H16M8 12H8.00897"
                stroke="#2A353D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <FormattedMessage id="TopbarDesktop.contactUs" />
          </NamedLink> */}
          <NamedLink name="ManageListingsPage" className={css.navigationLink}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 8.56907C2 7.37289 2.48238 6.63982 3.48063 6.08428L7.58987 3.79744C9.7431 2.59915 10.8197 2 12 2C13.1803 2 14.2569 2.59915 16.4101 3.79744L20.5194 6.08428C21.5176 6.63982 22 7.3729 22 8.56907C22 8.89343 22 9.05561 21.9646 9.18894C21.7785 9.88945 21.1437 10 20.5307 10H3.46928C2.85627 10 2.22152 9.88944 2.03542 9.18894C2 9.05561 2 8.89343 2 8.56907Z"
                stroke="#2A353D"
                strokeWidth="1.5"
              />
              <path
                d="M11.9959 7H12.0049"
                stroke="#2A353D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M4 10V18.5M8 10V18.5" stroke="#2A353D" strokeWidth="1.5" />
              <path d="M16 9.99988V18.4999M20 9.99988V18.4999" stroke="#2A353D" strokeWidth="1.5" />
              <path
                d="M19 18.5H5C3.34315 18.5 2 19.8431 2 21.5C2 21.7761 2.22386 22 2.5 22H21.5C21.7761 22 22 21.7761 22 21.5C22 19.8431 20.6569 18.5 19 18.5Z"
                stroke="#2A353D"
                strokeWidth="1.5"
              />
            </svg>

            <FormattedMessage id="TopbarMobileMenu.myProperties" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('MonthlyCasePage'))}
            name="MonthlyCasePage"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 18C18.4183 18 22 14.4183 22 10C22 5.58172 18.4183 2 14 2C9.58172 2 6 5.58172 6 10C6 14.4183 9.58172 18 14 18Z"
                stroke="#2A353D"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M3.15657 11C2.42523 12.1176 2 13.4535 2 14.8888C2 18.8162 5.18378 22 9.11116 22C10.5465 22 11.8824 21.5748 13 20.8434"
                stroke="#2A353D"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M14.7349 6C14.7349 5.58579 14.3991 5.25 13.9849 5.25C13.5707 5.25 13.2349 5.58579 13.2349 6L14.7349 6ZM13.2349 14C13.2349 14.4142 13.5707 14.75 13.9849 14.75C14.3991 14.75 14.7349 14.4142 14.7349 14H13.2349ZM15.069 8.36959C15.1504 8.77573 15.5456 9.03899 15.9517 8.9576C16.3579 8.87621 16.6211 8.48099 16.5397 8.07486L15.069 8.36959ZM12.7327 11.6178C12.6444 11.2131 12.2447 10.9567 11.84 11.045C11.4353 11.1334 11.1789 11.5331 11.2673 11.9378L12.7327 11.6178ZM13.9849 9.13495C13.3788 9.13495 13.1228 9.01911 13.0251 8.94128C12.9661 8.89417 12.8878 8.80275 12.8878 8.50223H11.3878C11.3878 9.09264 11.5613 9.69258 12.09 10.1141C12.5801 10.5049 13.2477 10.6349 13.9849 10.6349V9.13495ZM12.8878 8.50223C12.8878 8.11771 13.2803 7.63906 13.9849 7.63906V6.13906C12.6492 6.13906 11.3878 7.1049 11.3878 8.50223H12.8878ZM15.25 11.4981C15.25 11.8489 15.1282 12.0168 14.9752 12.1255C14.7842 12.2613 14.4549 12.3613 13.9849 12.3613V13.8613C14.6278 13.8613 15.306 13.7308 15.8444 13.3481C16.4207 12.9383 16.75 12.2996 16.75 11.4981H15.25ZM13.9849 10.6349C14.5987 10.6349 14.8988 10.7457 15.0379 10.8531C15.1337 10.9271 15.25 11.0729 15.25 11.4981H16.75C16.75 10.771 16.5304 10.1103 15.9545 9.66575C15.422 9.25464 14.7146 9.13495 13.9849 9.13495V10.6349ZM14.7349 6.88906L14.7349 6L13.2349 6L13.2349 6.88906L14.7349 6.88906ZM13.2349 13.1113V14H14.7349V13.1113H13.2349ZM13.9849 7.63906C14.6033 7.63906 14.9994 8.0226 15.069 8.36959L16.5397 8.07486C16.3057 6.90692 15.1881 6.13906 13.9849 6.13906V7.63906ZM13.9849 12.3613C13.2343 12.3613 12.8019 11.9344 12.7327 11.6178L11.2673 11.9378C11.529 13.1364 12.748 13.8613 13.9849 13.8613V12.3613Z"
                fill="#2A353D"
              />
            </svg>{' '}
            <FormattedMessage id="TopbarMobileMenu.MonthlyCasePage" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ScholarshipPage'))}
            name="ScholarshipPage"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 17.9808V9.70753C4 6.07416 4 4.25748 5.17157 3.12874C6.34315 2 8.22876 2 12 2C15.7712 2 17.6569 2 18.8284 3.12874C20 4.25748 20 6.07416 20 9.70753V17.9808C20 20.2867 20 21.4396 19.2272 21.8523C17.7305 22.6514 14.9232 19.9852 13.59 19.1824C12.8168 18.7168 12.4302 18.484 12 18.484C11.5698 18.484 11.1832 18.7168 10.41 19.1824C9.0768 19.9852 6.26947 22.6514 4.77285 21.8523C4 21.4396 4 20.2867 4 17.9808Z"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>{' '}
            <FormattedMessage id="TopbarMobileMenu.ScholarshipPage" />
          </NamedLink>
          <NamedLink className={classNames(css.navigationLink)} name="PrivacyPolicyPage">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 10C3.5 6.22876 3.5 4.34315 4.7448 3.17157C5.98959 2 7.99306 2 12 2H12.7727C16.0339 2 17.6645 2 18.7969 2.79784C19.1214 3.02643 19.4094 3.29752 19.6523 3.60289C20.5 4.66867 20.5 6.20336 20.5 9.27273V11.8182C20.5 14.7814 20.5 16.2629 20.0311 17.4462C19.2772 19.3486 17.6829 20.8491 15.6616 21.5586C14.4044 22 12.8302 22 9.68182 22C7.88275 22 6.98322 22 6.26478 21.7478C5.10979 21.3424 4.19875 20.4849 3.76796 19.3979C3.5 18.7217 3.5 17.8751 3.5 16.1818V10Z"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M20.5 12C20.5 13.8409 19.0076 15.3333 17.1667 15.3333C16.5009 15.3333 15.716 15.2167 15.0686 15.3901C14.4935 15.5442 14.0442 15.9935 13.8901 16.5686C13.7167 17.216 13.8333 18.0009 13.8333 18.6667C13.8333 20.5076 12.3409 22 10.5 22"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 7H15"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 11H11"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <FormattedMessage id="AuthenticationPage.termsAndConditionsPrivacyLinkText" />
          </NamedLink>{' '}
          <NamedLink className={css.navigationLink} name="TermsOfServicePage">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 10C3.5 6.22876 3.5 4.34315 4.7448 3.17157C5.98959 2 7.99306 2 12 2H12.7727C16.0339 2 17.6645 2 18.7969 2.79784C19.1214 3.02643 19.4094 3.29752 19.6523 3.60289C20.5 4.66867 20.5 6.20336 20.5 9.27273V11.8182C20.5 14.7814 20.5 16.2629 20.0311 17.4462C19.2772 19.3486 17.6829 20.8491 15.6616 21.5586C14.4044 22 12.8302 22 9.68182 22C7.88275 22 6.98322 22 6.26478 21.7478C5.10979 21.3424 4.19875 20.4849 3.76796 19.3979C3.5 18.7217 3.5 17.8751 3.5 16.1818V10Z"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M20.5 12C20.5 13.8409 19.0076 15.3333 17.1667 15.3333C16.5009 15.3333 15.716 15.2167 15.0686 15.3901C14.4935 15.5442 14.0442 15.9935 13.8901 16.5686C13.7167 17.216 13.8333 18.0009 13.8333 18.6667C13.8333 20.5076 12.3409 22 10.5 22"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 7H15"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 11H11"
                stroke="#2A353D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <FormattedMessage id="AuthenticationPage.termsAndConditionsTermsLinkText" />
          </NamedLink>{' '}
        </div>
        <div className={css.footer}>{login}</div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} disableProfileLink />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton
          rootClassName={css.logoutButton}
          onClick={() => {
            setLoginModalToggle(false);
            onCloseDrawer();
            onLogout();
          }}
        >
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: 'orders' }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.06164 15.1933L3.42688 13.1219C3.85856 10.6736 4.0744 9.44952 4.92914 8.72476C5.78389 8 7.01171 8 9.46734 8H14.5327C16.9883 8 18.2161 8 19.0709 8.72476C19.9256 9.44952 20.1414 10.6736 20.5731 13.1219L20.9384 15.1933C21.5357 18.5811 21.8344 20.275 20.9147 21.3875C19.995 22.5 18.2959 22.5 14.8979 22.5H9.1021C5.70406 22.5 4.00504 22.5 3.08533 21.3875C2.16562 20.275 2.4643 18.5811 3.06164 15.1933Z"
              stroke="#2A353D"
              strokeWidth="1.5"
            />
            <path
              d="M7.5 8L7.66782 5.98618C7.85558 3.73306 9.73907 2 12 2C14.2609 2 16.1444 3.73306 16.3322 5.98618L16.5 8"
              stroke="#2A353D"
              strokeWidth="1.5"
            />
            <path
              d="M15 11C14.87 12.4131 13.5657 13.5 12 13.5C10.4343 13.5 9.13002 12.4131 9 11"
              stroke="#2A353D"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>{' '}
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 8.56907C2 7.37289 2.48238 6.63982 3.48063 6.08428L7.58987 3.79744C9.7431 2.59915 10.8197 2 12 2C13.1803 2 14.2569 2.59915 16.4101 3.79744L20.5194 6.08428C21.5176 6.63982 22 7.3729 22 8.56907C22 8.89343 22 9.05561 21.9646 9.18894C21.7785 9.88945 21.1437 10 20.5307 10H3.46928C2.85627 10 2.22152 9.88944 2.03542 9.18894C2 9.05561 2 8.89343 2 8.56907Z"
              stroke="#2A353D"
              strokeWidth="1.5"
            />
            <path
              d="M11.9959 7H12.0049"
              stroke="#2A353D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M4 10V18.5M8 10V18.5" stroke="#2A353D" strokeWidth="1.5" />
            <path d="M16 9.99988V18.4999M20 9.99988V18.4999" stroke="#2A353D" strokeWidth="1.5" />
            <path
              d="M19 18.5H5C3.34315 18.5 2 19.8431 2 21.5C2 21.7761 2.22386 22 2.5 22H21.5C21.7761 22 22 21.7761 22 21.5C22 19.8431 20.6569 18.5 19 18.5Z"
              stroke="#2A353D"
              strokeWidth="1.5"
            />
          </svg>{' '}
          <FormattedMessage id="TopbarMobileMenu.myProperties" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <Icons name="profileSettings" />{' '}
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z"
              stroke="#2A353D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z"
              stroke="#2A353D"
              strokeWidth="1.5"
            />
          </svg>
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('MonthlyCasePage'))}
          name="MonthlyCasePage"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 18C18.4183 18 22 14.4183 22 10C22 5.58172 18.4183 2 14 2C9.58172 2 6 5.58172 6 10C6 14.4183 9.58172 18 14 18Z"
              stroke="#2A353D"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M3.15657 11C2.42523 12.1176 2 13.4535 2 14.8888C2 18.8162 5.18378 22 9.11116 22C10.5465 22 11.8824 21.5748 13 20.8434"
              stroke="#2A353D"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M14.7349 6C14.7349 5.58579 14.3991 5.25 13.9849 5.25C13.5707 5.25 13.2349 5.58579 13.2349 6L14.7349 6ZM13.2349 14C13.2349 14.4142 13.5707 14.75 13.9849 14.75C14.3991 14.75 14.7349 14.4142 14.7349 14H13.2349ZM15.069 8.36959C15.1504 8.77573 15.5456 9.03899 15.9517 8.9576C16.3579 8.87621 16.6211 8.48099 16.5397 8.07486L15.069 8.36959ZM12.7327 11.6178C12.6444 11.2131 12.2447 10.9567 11.84 11.045C11.4353 11.1334 11.1789 11.5331 11.2673 11.9378L12.7327 11.6178ZM13.9849 9.13495C13.3788 9.13495 13.1228 9.01911 13.0251 8.94128C12.9661 8.89417 12.8878 8.80275 12.8878 8.50223H11.3878C11.3878 9.09264 11.5613 9.69258 12.09 10.1141C12.5801 10.5049 13.2477 10.6349 13.9849 10.6349V9.13495ZM12.8878 8.50223C12.8878 8.11771 13.2803 7.63906 13.9849 7.63906V6.13906C12.6492 6.13906 11.3878 7.1049 11.3878 8.50223H12.8878ZM15.25 11.4981C15.25 11.8489 15.1282 12.0168 14.9752 12.1255C14.7842 12.2613 14.4549 12.3613 13.9849 12.3613V13.8613C14.6278 13.8613 15.306 13.7308 15.8444 13.3481C16.4207 12.9383 16.75 12.2996 16.75 11.4981H15.25ZM13.9849 10.6349C14.5987 10.6349 14.8988 10.7457 15.0379 10.8531C15.1337 10.9271 15.25 11.0729 15.25 11.4981H16.75C16.75 10.771 16.5304 10.1103 15.9545 9.66575C15.422 9.25464 14.7146 9.13495 13.9849 9.13495V10.6349ZM14.7349 6.88906L14.7349 6L13.2349 6L13.2349 6.88906L14.7349 6.88906ZM13.2349 13.1113V14H14.7349V13.1113H13.2349ZM13.9849 7.63906C14.6033 7.63906 14.9994 8.0226 15.069 8.36959L16.5397 8.07486C16.3057 6.90692 15.1881 6.13906 13.9849 6.13906V7.63906ZM13.9849 12.3613C13.2343 12.3613 12.8019 11.9344 12.7327 11.6178L11.2673 11.9378C11.529 13.1364 12.748 13.8613 13.9849 13.8613V12.3613Z"
              fill="#2A353D"
            />
          </svg>{' '}
          <FormattedMessage id="TopbarMobileMenu.MonthlyCasePage" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ScholarshipPage'))}
          name="ScholarshipPage"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 17.9808V9.70753C4 6.07416 4 4.25748 5.17157 3.12874C6.34315 2 8.22876 2 12 2C15.7712 2 17.6569 2 18.8284 3.12874C20 4.25748 20 6.07416 20 9.70753V17.9808C20 20.2867 20 21.4396 19.2272 21.8523C17.7305 22.6514 14.9232 19.9852 13.59 19.1824C12.8168 18.7168 12.4302 18.484 12 18.484C11.5698 18.484 11.1832 18.7168 10.41 19.1824C9.0768 19.9852 6.26947 22.6514 4.77285 21.8523C4 21.4396 4 20.2867 4 17.9808Z"
              stroke="#2A353D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{' '}
          <FormattedMessage id="TopbarMobileMenu.ScholarshipPage" />
        </NamedLink>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink
          className={css.loginLink}
          name={isAuthenticated ? 'NewListingPage' : 'SignupPage'}
        >
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
