import { revokeSubscription, startSubscription } from '../../util/api';
import { stripeCustomer } from '../PaymentMethodsPage/PaymentMethodsPage.duck';
import { setInitialValues as setInitialValuesForPaymentMethods } from '../../ducks/paymentMethods.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SUBSCRIPTION_SUCCESS = 'app/SubscriptionPage/SUBSCRIPTION_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  paymentSuccess: false,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        paymentSuccess: true,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const startSubscriptionSuccess = () => ({
  type: SUBSCRIPTION_SUCCESS,
});

export const createSubscription = values => async (dispatch, getState, sdk) => {
  try {
    const subscription = await startSubscription({ ...values });
    await dispatch(fetchCurrentUser());
    return subscription;
  } catch (error) {
    console.error('Error creating subscription:', error);
    // Handle the error (e.g., show an error message, dispatch an action)
    throw error; // Rethrow the error to propagate it further if needed
  }
};
export const cancelSubscription = values => async (dispatch, getState, sdk) => {
  try {
    const subscription = await revokeSubscription({ ...values });
    await dispatch(fetchCurrentUser());
    return subscription;
  } catch (error) {
    console.error('Error canceling subscription:', error);
    // Handle the error (e.g., show an error message, dispatch an action)
    throw error; // Rethrow the error to propagate it further if needed
  }
};

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(setInitialValuesForPaymentMethods());
  return dispatch(stripeCustomer());
};
