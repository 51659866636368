import React from 'react';
import css from './CountryList.module.css';
import classNames from 'classnames';

function CountryList() {
  return (
    <ul className={css.GlobalCountryList}>
      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=AU"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryAU, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="AU"
            loading="lazy"
          />
          Australia
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=AT"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryAT, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="AT"
            loading="lazy"
          />
          Austria
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=BE"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryBE, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="BE"
            loading="lazy"
          />
          Belgium
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=BR"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryBR, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="BR"
            loading="lazy"
          />
          Brazil
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=BG"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryBG, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="BG"
            loading="lazy"
          />
          Bulgaria
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=CA"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryCA, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="CA"
            loading="lazy"
          />
          Canada
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=HR"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryHR, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="HR"
            loading="lazy"
          />
          Croatia
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=CY"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryCY, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="CY"
            loading="lazy"
          />
          Cyprus
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=CZ"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryCZ, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="CZ"
            loading="lazy"
          />
          Czech Republic
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=DK"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryDK, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="DK"
            loading="lazy"
          />
          Denmark
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=EE"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryEE, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="EE"
            loading="lazy"
          />
          Estonia
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=FI"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryFI, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="FI"
            loading="lazy"
          />
          Finland
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=FR"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryFR, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="FR"
            loading="lazy"
          />
          France
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=DE"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryDE, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="DE"
            loading="lazy"
          />
          Germany
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=GI"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryGI, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="GI"
            loading="lazy"
          />
          Gibraltar
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=GR"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryGR, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="GR"
            loading="lazy"
          />
          Greece
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=HK"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryHK, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="HK"
            loading="lazy"
          />
          Hong Kong
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=HU"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryHU, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="HU"
            loading="lazy"
          />
          Hungary
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=IN"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryIN, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="IN"
            loading="lazy"
          />
          India
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=ID"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryID, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="ID"
            loading="lazy"
          />
          Indonesia
          <span
            className="
Badge
Badge--accented




GlobalCountryList__invitationRequiredBadge
"
          >
            Invite only
          </span>
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=IE"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryIE, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="IE"
            loading="lazy"
          />
          Ireland
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=IT"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryIT, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="IT"
            loading="lazy"
          />
          Italy
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=JP"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryJP, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="JP"
            loading="lazy"
          />
          Japan
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=LV"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryLV, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="LV"
            loading="lazy"
          />
          Latvia
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=LI"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryLI, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="LI"
            loading="lazy"
          />
          Liechtenstein
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=LT"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryLT, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="LT"
            loading="lazy"
          />
          Lithuania
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=LU"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryLU, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="LU"
            loading="lazy"
          />
          Luxembourg
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=MY"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryMY, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="MY"
            loading="lazy"
          />
          Malaysia
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=MT"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryMT, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="MT"
            loading="lazy"
          />
          Malta
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=MX"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryMX, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="MX"
            loading="lazy"
          />
          Mexico
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=NL"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryNL, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="NL"
            loading="lazy"
          />
          Netherlands
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=NZ"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryNZ, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="NZ"
            loading="lazy"
          />
          New Zealand
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=NO"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryNO, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="NO"
            loading="lazy"
          />
          Norway
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=PL"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryPL, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="PL"
            loading="lazy"
          />
          Poland
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=PT"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryPT, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="PT"
            loading="lazy"
          />
          Portugal
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=RO"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryRO, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="RO"
            loading="lazy"
          />
          Romania
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=SG"
        >
          <img
            className={classNames(css.Flag, css.FlagCountrySG, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="SG"
            loading="lazy"
          />
          Singapore
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=SK"
        >
          <img
            className={classNames(css.Flag, css.FlagCountrySK, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="SK"
            loading="lazy"
          />
          Slovakia
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=SI"
        >
          <img
            className={classNames(css.Flag, css.FlagCountrySI, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="SI"
            loading="lazy"
          />
          Slovenia
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=ES"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryES, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="ES"
            loading="lazy"
          />
          Spain
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=SE"
        >
          <img
            className={classNames(css.Flag, css.FlagCountrySE, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="SE"
            loading="lazy"
          />
          Sweden
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=CH"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryCH, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="CH"
            loading="lazy"
          />
          Switzerland
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=TH"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryTH, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="TH"
            loading="lazy"
          />
          Thailand
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=AE"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryAE, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="AE"
            loading="lazy"
          />
          United Arab Emirates
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=GB"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryGB, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="GB"
            loading="lazy"
          />
          United Kingdom
        </a>
      </li>

      <li className={css.GlobalCountryList__listItem}>
        <a
          className={css.GlobalCountryList__link}
          href="https://dashboard.stripe.com/register?country=US"
        >
          <img
            className={classNames(css.Flag, css.FlagCountryUS, css.GlobalCountryList__flagIcon)}
            src="https://images.ctfassets.net/fzn2n1nzq965/5JNjy04LAsNrA6xBh7Ao6z/0627f061f30ba1e46b9f2f8e2a0e48c1/flagIcons.svg"
            alt="US"
            loading="lazy"
          />
          United States
        </a>
      </li>
    </ul>
  );
}

export default CountryList;
