import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconWifi = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="20px"
      height="20px"
      viewBox="0 -2 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <title>wifi [#1012]</title> <desc>Created with Sketch.</desc> <defs> </defs>{' '}
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          {' '}
          <g
            id="Dribbble-Light-Preview"
            transform="translate(-303.000000, -3684.000000)"
            fill="#000000"
          >
            {' '}
            <g id="icons" transform="translate(56.000000, 160.000000)">
              {' '}
              <path
                d="M252.600198,3532.58575 L254,3533.99975 L255.399802,3532.58575 C254.626644,3531.80475 253.373356,3531.80475 252.600198,3532.58575 M249.800594,3529.75775 L251.200396,3531.17175 C252.743742,3529.61175 255.256258,3529.61175 256.800594,3531.17175 L258.200396,3529.75775 C255.880922,3527.41375 252.120068,3527.41375 249.800594,3529.75775 M261,3526.92875 L259.600198,3528.34275 C256.512516,3525.22375 251.488474,3525.22375 248.399802,3528.34275 L247,3526.92875 C250.86579,3523.02375 257.13421,3523.02375 261,3526.92875"
                id="wifi-[#1012]"
              >
                {' '}
              </path>{' '}
            </g>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};

IconWifi.defaultProps = {
  className: null,
  rootClassName: null,
};

IconWifi.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconWifi;
