import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconMonitor = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.5832 37.4376H13.4165C5.12484 37.4376 2.604 34.9167 2.604 26.6251V13.4167C2.604 5.12508 5.12484 2.60425 13.4165 2.60425H36.5623C44.854 2.60425 47.3748 5.12508 47.3748 13.4167V26.6042C47.3957 34.9167 44.8748 37.4376 36.5832 37.4376ZM13.4165 5.72925C6.87484 5.72925 5.729 6.87508 5.729 13.4167V26.6042C5.729 33.1459 6.87484 34.2917 13.4165 34.2917H36.5623C43.104 34.2917 44.2498 33.1459 44.2498 26.6042V13.4167C44.2498 6.87508 43.104 5.72925 36.5623 5.72925H13.4165Z"
        fill="#3e5ba6"
      />
      <path
        d="M25 47.3958C24.1458 47.3958 23.4375 46.6875 23.4375 45.8333V35.875C23.4375 35.0208 24.1458 34.3125 25 34.3125C25.8542 34.3125 26.5625 35.0208 26.5625 35.875V45.8333C26.5625 46.6875 25.8542 47.3958 25 47.3958Z"
        fill="#3e5ba6"
      />
      <path
        d="M45.8332 28.6458H4.1665C3.31234 28.6458 2.604 27.9374 2.604 27.0833C2.604 26.2291 3.31234 25.5208 4.1665 25.5208H45.8332C46.6873 25.5208 47.3957 26.2291 47.3957 27.0833C47.3957 27.9374 46.6873 28.6458 45.8332 28.6458Z"
        fill="#3e5ba6"
      />
      <path
        d="M34.375 47.3958H15.625C14.7708 47.3958 14.0625 46.6874 14.0625 45.8333C14.0625 44.9791 14.7708 44.2708 15.625 44.2708H34.375C35.2292 44.2708 35.9375 44.9791 35.9375 45.8333C35.9375 46.6874 35.2292 47.3958 34.375 47.3958Z"
        fill="#3e5ba6"
      />
    </svg>
  );
};

IconMonitor.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMonitor.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMonitor;
