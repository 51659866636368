import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconPlusCircle = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0002 3.3335C10.8168 3.3335 3.3335 10.8168 3.3335 20.0002C3.3335 29.1835 10.8168 36.6668 20.0002 36.6668C29.1835 36.6668 36.6668 29.1835 36.6668 20.0002C36.6668 10.8168 29.1835 3.3335 20.0002 3.3335ZM26.6668 21.2502H21.2502V26.6668C21.2502 27.3502 20.6835 27.9168 20.0002 27.9168C19.3168 27.9168 18.7502 27.3502 18.7502 26.6668V21.2502H13.3335C12.6502 21.2502 12.0835 20.6835 12.0835 20.0002C12.0835 19.3168 12.6502 18.7502 13.3335 18.7502H18.7502V13.3335C18.7502 12.6502 19.3168 12.0835 20.0002 12.0835C20.6835 12.0835 21.2502 12.6502 21.2502 13.3335V18.7502H26.6668C27.3502 18.7502 27.9168 19.3168 27.9168 20.0002C27.9168 20.6835 27.3502 21.2502 26.6668 21.2502Z"
        fill="#3e5ba6"
      />
    </svg>
  );
};

IconPlusCircle.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPlusCircle.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPlusCircle;
