import React, { useContext, useEffect } from 'react';
import { useMyContext } from './StateHolder';
import { useSelector } from 'react-redux';
import { isLoginInProgressSelector, isLogoutInProgressSelector } from '../ducks/auth.duck';

// First create the context to hold shared functions
const MyContextFunctions = React.createContext();

// Then create a ContextFunctions wrapper component
// to hold the shared functions that the components need.
// to hold the shared functions that the components need.
const ContextFunctions = props => {
  // Bring stateholders from another context
  const { setLoginModalToggle } = useMyContext();

  const isLoginInProgress = useSelector(isLoginInProgressSelector);
  const isLogoutInProgress = useSelector(isLogoutInProgressSelector);

  // useEffect(() => {
  //   setLoginModalToggle(false);
  // }, [isLoginInProgress, isLogoutInProgress]);

  const onLoginModalToggle = bool => {
    setLoginModalToggle(bool);
  };

  return (
    <MyContextFunctions.Provider
      value={{
        onLoginModalToggle,
      }}
    >
      {props.children}
    </MyContextFunctions.Provider>
  );
};

export const useMyContextFunctions = () => useContext(MyContextFunctions);

export default ContextFunctions;
