import React, { useEffect, useState } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { sendVerificationEmail, hasCurrentUserErrors } from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/auth.duck';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { Modal, Topbar } from '../../components';
import { withViewport } from '../../util/uiHelpers';
import { useMyContext } from '../../custom-contexts/StateHolder';
import { useIntercom } from 'react-use-intercom';
import CountryList from '../../components/Topbar/CountryList/CountryList';
import css from '../../components/Topbar/Topbar.module.css';
export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    isHeaderSticky,
    isWhiteBgNav,
    isSearchPage,
    isListingPage,
    viewport,
    ...rest
  } = props;

  const { setLoginModalToggle } = useMyContext();
  const { boot, shutdown } = useIntercom();
  const [isCountriesModalOpen, setCountriesModalOpen] = useState(false);

  useEffect(() => {
    boot();
  }, []);

  const stripeCountriesModal = (
    <Modal
      isCountryModal={true}
      isOpen={isCountriesModalOpen}
      onClose={() => setCountriesModalOpen(false)}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.chooseCountry}>
        <h1>BnBunite availability</h1>
        <p>
          BnBunite is currently supported in 46 countries, with more to come in early 2024. If your
          bank account is listed in one of these countries, then you can sell to customers anywhere
          in the world.
        </p>
        <CountryList />
      </div>
    </Modal>
  );

  return (
    <>
      <Topbar
        authInProgress={authInProgress}
        currentPage={currentPage}
        currentSearchParams={currentSearchParams}
        currentUser={currentUser}
        stripeCountriesModal={stripeCountriesModal}
        isCountriesModalOpen={isCountriesModalOpen}
        setCountriesModalOpen={setCountriesModalOpen}
        currentUserHasListings={currentUserHasListings}
        currentUserHasOrders={currentUserHasOrders}
        history={history}
        isAuthenticated={isAuthenticated}
        authScopes={authScopes}
        location={location}
        notificationCount={notificationCount}
        onLogout={onLogout}
        onManageDisableScrolling={onManageDisableScrolling}
        onResendVerificationEmail={onResendVerificationEmail}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
        showGenericError={hasGenericError}
        isHeaderSticky={isHeaderSticky}
        isWhiteBgNav={isWhiteBgNav}
        viewport={viewport}
        setLoginModalToggle={setLoginModalToggle}
        isSearchPage={isSearchPage}
        isListingPage={isListingPage}
        {...rest}
      />
      {stripeCountriesModal}
    </>
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
  authScopes: null,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes } = state.auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    notificationCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport
)(TopbarContainerComponent);

export default TopbarContainer;
