import React from 'react';
import { string } from 'prop-types';
import Decimal from 'decimal.js';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_CUSTOMER_COMMISSION } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

const LINE_ITEM_SERVICE_FEE = 'line-item/service-fee';
const LINE_ITEM_TRANSACTION_FEE = 'line-item/transaction-fee';

const lineItemsTotal = (lineItems, marketplaceCurrency) => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : marketplaceCurrency;
  return new Money(amount, currency);
};

const LineItemCustomerCommissionPartialRefundMaybe = props => {
  const { lineItems, isCustomer, marketplaceName, intl, transaction, marketplaceCurrency } = props;

  const validLineItems = lineItems.filter(
    item =>
      ![LINE_ITEM_TRANSACTION_FEE, LINE_ITEM_SERVICE_FEE].includes(item.code) && !item.reversal
  );
  const validServiceFeeLineItems = lineItems.filter(
    item => [LINE_ITEM_TRANSACTION_FEE, LINE_ITEM_SERVICE_FEE].includes(item.code) && !item.reversal
  );

  // line totals of those line items combined
  const subTotal = lineItemsTotal(validLineItems, marketplaceCurrency);
  const partialAmount = new Decimal(subTotal.amount).dividedBy(2).toNumber();

  const partialAmountFormatted = new Money(partialAmount, marketplaceCurrency);

  const customerServiceFee = validServiceFeeLineItems?.find(lineItem =>
    lineItem?.includeFor?.includes('customer')
  );
  const providerServiceFee = validServiceFeeLineItems?.find(lineItem =>
    lineItem?.includeFor?.includes('provider')
  );

  const totalPartialPrice = isCustomer
    ? partialAmount + customerServiceFee?.lineTotal?.amount
    : partialAmount - Math.abs(providerServiceFee?.lineTotal?.amount);

  const partialTotalAmountFormatted = new Money(totalPartialPrice, marketplaceCurrency);
  console.log(providerServiceFee, 'partialTotalAmountFormatted');
  return partialAmount ? (
    <>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.partialRefund" />
        </span>
        <span className={css.itemValue}>{formatMoney(intl, partialAmountFormatted)}</span>
      </div>
      <hr className={css.totalDivider} />
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.total" />
        </span>
        <span className={css.itemValue}>{formatMoney(intl, partialTotalAmountFormatted)}</span>
      </div>
    </>
  ) : null;
};

LineItemCustomerCommissionPartialRefundMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  marketplaceName: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerCommissionPartialRefundMaybe;
