import React from 'react';
import { useDropzone } from 'react-dropzone';
import IconAttachment from '../IconAttachment/IconAttachment';
import css from './DropZone.module.css';

function DropZone(props) {
  const { onAttachmentUpload, className, label, labelType = 'text', acceptFile = null } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFile
      ? { 'application/pdf': [] }
      : {
          'image/*': [],
        },
    onDrop: acceptedFiles => {
      for (let index = 0; index < acceptedFiles.length; index++) {
        const file = acceptedFiles[index];
        onAttachmentUpload(file);
      }
    },
  });

  const renderLabel =
    labelType === 'button' ? (
      <span className={css.uploadButton}>{label || 'Upload'}</span>
    ) : label ? (
      label
    ) : (
      <IconAttachment />
    );

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      <div className={css.label}>{renderLabel}</div>
    </div>
  );
}
export default DropZone;
