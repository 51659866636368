/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

// Return captilized string
export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string') {
    return '';
  }
  const string = str && str.toString();
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
};

//This is a sharetribe response import with data.data
export const getPublicDataAttributeValue = (attributeName, data) => {
  if (data && data.attributes && data.attributes.publicData) {
    const publicData = data.attributes.publicData;
    if (publicData.hasOwnProperty(attributeName)) {
      return publicData[attributeName];
    }
  }
  return '';
};

export const getProductImages = images => {
  if (!Array.isArray(images)) {
    return [];
  }
  return images
    .map(
      item =>
        item?.attributes?.variants &&
        (item?.attributes?.variants['scaled-medium']?.url ||
          item?.attributes?.variants['listing-card-2x']?.url)
    )
    .filter(Boolean);
};

/**
 * Returns an array of wishlist items associated with a user's profile.
 * @param {object} currentUser - The user object.
 * @returns {array} An array of wishlist items, or an empty array if input is invalid or wishlist is missing.
 */
export const getWishlist = currentUser => {
  return (
    (currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.wishlist) || []
  );
};

export const copyToClipboard = async text => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      return;
    }

    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus({
      preventScroll: true,
    });
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  } catch (e) {
    console.error(`Copying failed with error: ${e}`);
    throw e;
  }
};

export const appendAverageReviews = reviews => {
  const MIN_RATING = 1;
  const MAX_RATING = 5;

  const filteredReviews =
    Array.isArray(reviews) &&
    reviews.filter(r => r?.attributes?.rating !== null && r?.attributes?.state === 'public');

  const totalReviews = filteredReviews?.length || 0;

  if (!totalReviews) {
    return 0.0;
  }

  const ratings = filteredReviews.map(review => review?.attributes?.rating);
  const validRatings = ratings.filter(r => r >= MIN_RATING && r <= MAX_RATING);

  if (!validRatings.length) {
    return 0.0;
  }

  const starSum = validRatings.reduce((partialSum, rating) => partialSum + rating, 0);
  const averageRating = starSum / validRatings.length;

  return averageRating || 0;
};
