import React, { useState } from 'react';
import css from '../LandingSearchForm.module.css';
import { FieldCheckbox } from '../../../../components';
import classNames from 'classnames';

const Guests = ({ onApply, onClose }) => {
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [pets, setPets] = useState(false);

  const handleAdultsChange = value => {
    setAdults(value);
  };

  const handleChildrenChange = value => {
    setChildren(value);
  };

  const handlePetsChange = e => {
    const checked = e.target.checked;
    setPets(checked);
  };

  const handleApply = () => {
    onApply({ adults, children, pets });
    onClose();
  };

  return (
    <div className={css.guestsDropdown}>
      <div className={css.choosePassangers}>
        <div className={css.passangerType}>
          <div className={css.passangerCount}>
            <span className={css.personCategory}>Adults</span>
          </div>
          <div className={css.selectBox}>
            <button onClick={() => handleAdultsChange(adults - 1)} disabled={adults === 0}>
              -
            </button>
            <span className={css.totalPersons}>{adults}</span>
            <button onClick={() => handleAdultsChange(adults + 1)}>+</button>
          </div>
        </div>
        <div className={css.passangerType}>
          <div className={css.passangerCount}>
            <div className={css.descriptionContainer}>
              <div className={css.personCategory}>Children</div>
              <div className={css.petsDescription}>Ages 0 to 17</div>
            </div>
          </div>
          <div className={css.selectBox}>
            <button onClick={() => handleChildrenChange(children - 1)} disabled={children === 0}>
              -
            </button>
            <span className={css.totalPersons}>{children}</span>
            <button onClick={() => handleChildrenChange(children + 1)}>+</button>
          </div>
        </div>
        <div className={css.passangerType}>
          <div className={css.passangerCount}>
            <div className={css.descriptionContainer}>
              <div className={css.personCategory}>Pets</div>
              <div className={css.petsDescription}>
                (Only properties that permit pets will be displayed.)
              </div>
            </div>
          </div>

          <div className={classNames(css.selectBox, css.checkBox)}>
            <FieldCheckbox
              id="pets"
              className={css.deliveryCheckbox}
              name="pets"
              label=""
              value={true}
              onChange={handlePetsChange}
            />
          </div>
        </div>
      </div>
      <button className={css.applyBtn} onClick={handleApply}>
        Apply
      </button>
    </div>
  );
};

export default Guests;
