import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  NamedLink,
  IconSocialMediaFacebook,
  FieldCheckbox,
  FieldCheckboxGroup,
} from '../../../components';

import css from './LoginForm.module.css';
import IconSocialMediaGoogle from '../../../components/IconSocialMediaGoogle/IconSocialMediaGoogle';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../util/routes';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useMyContext } from '../../../custom-contexts/StateHolder';
import { SocialLoginButtonsMaybe } from '../AuthenticationPage';
import { useSelector } from 'react-redux';
import { loginErrorSelector } from '../../../ducks/auth.duck';

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        hideHeader = false
      } = fieldRenderProps;

      const history = useHistory();
      const location = useLocation();
      const loginError = useSelector(loginErrorSelector);

      const loginErrorMessage = (
        <div className={css.error}>
          {intl.formatMessage({
            id: 'AuthenticationPage.loginFailed',
          })}
        </div>
      );

      const errorMessage = (error, message) => (error ? message : null);
      const loginOrSignupError = errorMessage(loginError, loginErrorMessage);

      const { setLoginModalToggle } = useMyContext();

      // email
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const routeConfiguration = useRouteConfiguration();

      const onPushToPage = pagename => {
        history.push(createResourceLocatorString(pagename, routeConfiguration, {}, {}));
        setLoginModalToggle(false);
      };

      const passwordRecoveryLink = (
        <span onClick={() => onPushToPage('PasswordRecoveryPage')} className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.forgotPassword" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {hideHeader ? null : (
            <div className={css.loginFormHead}>
              <h2>Welcome Back</h2>
              <p>Please Enter your details to sign in</p>
            </div>
          )}
          <div className={css.SocialMediaLogin}>
            {/* <SocialLoginButtonsMaybe
              showFacebookLogin={true}
              showGoogleLogin={true}
              from={location?.state?.from}
              hideOrText
            /> */}
            {/* <NamedLink name="LandingPage">
              <IconSocialMediaFacebook />
            </NamedLink>
            <NamedLink name="LandingPage">
              <IconSocialMediaGoogle />
            </NamedLink> */}
          </div>
          {/* <div className={css.orText}>OR</div> */}
          <div className={css.formRow}>
            <div className={css.formFld}>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formFld}>
              <FieldTextInput
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                // autoComplete="current-password"
                label={passwordLabel}
                placeholder={passwordPlaceholder}
                validate={passwordRequired}
              />
            </div>
          </div>
          <div className={classNames(css.formRow, css.halfWidth)}>
            <div className={css.formFld}>{passwordRecoveryLink}</div>
          </div>
          {loginOrSignupError}
          <div className={css.formRow}>
            <div className={css.formFld}>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="LoginForm.logIn" />
              </PrimaryButton>
              <div className={css.haveAccount}>
                Don't have an account?{' '}
                <span className={css.signupUserPage} onClick={() => onPushToPage('SignupUserPage')}>
                  <FormattedMessage id="TopbarDesktop.signup" />
                </span>{' '}
              </div>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
