import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconClose.module.css';
const SIZE_SMALL = 'small';

const IconClose = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === SIZE_SMALL) {
    return (
      <svg
        className={classes}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#000"
          d="M1.57577 13.8617C1.21161 13.8617 0.847441 13.7275 0.559941 13.44C0.00410809 12.8842 0.00410809 11.9642 0.559941 11.4083L11.4083 0.560002C11.9641 0.00416914 12.8841 0.00416914 13.4399 0.560002C13.9958 1.11584 13.9958 2.03583 13.4399 2.59167L2.59161 13.44C2.32327 13.7275 1.93994 13.8617 1.57577 13.8617Z"
        />
        <path
          fill="#000"
          d="M12.4241 13.8617C12.0599 13.8617 11.6958 13.7275 11.4083 13.44L0.559941 2.59167C0.00410809 2.03583 0.00410809 1.11584 0.559941 0.560002C1.11577 0.00416914 2.03577 0.00416914 2.59161 0.560002L13.4399 11.4083C13.9958 11.9642 13.9958 12.8842 13.4399 13.44C13.1524 13.7275 12.7883 13.8617 12.4241 13.8617Z"
        />
      </svg>
    );
  }

  return (
    <svg
      className={classes}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#000"
        d="M1.57577 13.8617C1.21161 13.8617 0.847441 13.7275 0.559941 13.44C0.00410809 12.8842 0.00410809 11.9642 0.559941 11.4083L11.4083 0.560002C11.9641 0.00416914 12.8841 0.00416914 13.4399 0.560002C13.9958 1.11584 13.9958 2.03583 13.4399 2.59167L2.59161 13.44C2.32327 13.7275 1.93994 13.8617 1.57577 13.8617Z"
      />
      <path
        fill="#000"
        d="M12.4241 13.8617C12.0599 13.8617 11.6958 13.7275 11.4083 13.44L0.559941 2.59167C0.00410809 2.03583 0.00410809 1.11584 0.559941 0.560002C1.11577 0.00416914 2.03577 0.00416914 2.59161 0.560002L13.4399 11.4083C13.9958 11.9642 13.9958 12.8842 13.4399 13.44C13.1524 13.7275 12.7883 13.8617 12.4241 13.8617Z"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconClose.defaultProps = {
  className: null,
  rootClassName: null,
};

IconClose.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconClose;
