import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaGoogle.module.css';

const IconSocialMediaGoogle = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9577 15.1666C18.6405 16.2699 18.012 17.2584 17.1473 18.0135C16.2827 18.7686 15.2186 19.2584 14.0827 19.4241C13.1944 19.553 12.2892 19.5011 11.4215 19.2714C10.5537 19.0417 9.74137 18.6391 9.03312 18.0877C8.32487 17.5363 7.73534 16.8474 7.29993 16.0625C6.86453 15.2776 6.59222 14.4128 6.49935 13.52C6.42741 12.6237 6.54222 11.7222 6.8365 10.8726C7.13079 10.0229 7.59816 9.24356 8.20907 8.58378C8.81997 7.924 9.56112 7.39815 10.3857 7.03947C11.2102 6.68079 12.1002 6.49708 12.9994 6.49997C13.8419 6.50115 14.6765 6.66293 15.4585 6.97663C15.5817 7.03191 15.721 7.03923 15.8492 6.99715C15.9775 6.95507 16.0854 6.86662 16.1518 6.74913L17.7119 3.8783C17.7454 3.81217 17.7656 3.74007 17.7713 3.66612C17.7769 3.59218 17.7679 3.51784 17.7448 3.44739C17.7216 3.37693 17.6848 3.31174 17.6364 3.25555C17.588 3.19936 17.5289 3.15329 17.4627 3.11997C15.7875 2.36154 13.9469 2.04199 12.1142 2.19141C10.2814 2.34083 8.51686 2.9543 6.98669 3.97405C5.45652 4.99381 4.2111 6.38627 3.36774 8.02029C2.52437 9.65432 2.11081 11.4761 2.16602 13.3141C2.27277 16.044 3.39492 18.6357 5.31264 20.5815C7.23036 22.5272 9.80547 23.6869 12.5335 23.8333C15.3913 23.96 18.1832 22.9513 20.3 21.0273C22.4168 19.1033 23.6867 16.4201 23.8327 13.5633C23.8327 13.3466 23.8327 12.1008 23.8327 11.3966C23.8299 11.2538 23.772 11.1177 23.671 11.0167C23.57 10.9157 23.4338 10.8577 23.291 10.855H13.541C13.3974 10.855 13.2596 10.912 13.158 11.0136C13.0564 11.1152 12.9994 11.253 12.9994 11.3966V14.6466C12.9994 14.7903 13.0564 14.9281 13.158 15.0296C13.2596 15.1312 13.3974 15.1883 13.541 15.1883H18.9577"
        fill="#E8483E"
      />
    </svg>
  );
};

IconSocialMediaGoogle.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaGoogle.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaGoogle;
