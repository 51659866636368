import React, { useEffect } from 'react';
import { useMyContext } from '../../../custom-contexts/StateHolder';
import AuthModal from './AuthModal/AuthModal';

import { useDispatch, useSelector } from 'react-redux';
import { manageDisableScrolling } from '../../../ducks/ui.duck';
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import css from './AuthWrapper.module.css';
import LoginForm from '../../../containers/AuthenticationPage/LoginForm/LoginForm';
import { authenticationInProgress, isAuthenticatedSelector, login } from '../../../ducks/auth.duck';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function AuthWrapper() {
  const { loginModalToggle, setLoginModalToggle } = useMyContext();
  const dispatch = useDispatch();
  const modalRef = React.useRef(null);

  const submitLogin = params => {
    const { email, password } = params;
    return dispatch(login(email, password));
  };

  const authInProgress = useSelector(authenticationInProgress);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const history = useHistory();

  const onManageDisableScrolling = (componentId, disableScrolling) => {
    dispatch(manageDisableScrolling(componentId, disableScrolling));
  };

  const modal = (
    <AuthModal
      id="VerifyDeletingPaymentMethod"
      isOpen={!isAuthenticated && loginModalToggle}
      onClose={() => {
        history.push('/');
        setLoginModalToggle(false);
      }}
      usePortal
      contentClassName={css.modalContent}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <LoginForm className={css.loginForm} onSubmit={submitLogin} inProgress={authInProgress} />
    </AuthModal>
  );

  return <div ref={modalRef}>{modal}</div>;
}

export default AuthWrapper;
