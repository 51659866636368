import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { ImageSlider, NamedLink } from '../../components';
import IconBedroom from '../IconBedroom/IconBedroom';
import IconGuest from '../IconGuest/IconGuest';
import IconStar from '../IconStar/IconStar';
import IconStarFilled from '../IconStarFilled/IconStarFilled';
import {
  getProductImages,
  getPublicDataAttributeValue,
  isArrayLength,
  getWishlist,
} from '../../util/genericHelpers';
import { categoryOptions } from '../../config/configListing';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { selectCurrentUser } from '../../ducks/user.duck';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../ducks/auth.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import IconHeart from '../IconHeart/IconHeart';
import IconHeartFilled from '../IconHeartFilled/IconHeartFilled';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const getLastElementFromAddress = address => {
  if (!address) return ''; // Return an empty string if the address is null or undefined

  const splitAddress = address.split(','); // Split the address by commas

  if (splitAddress.length < 3) return address; // If there are less than 3 elements, return the original address

  // Remove numbers from each element
  const sanitizedAddress = splitAddress.map(element => element.replace(/\d/g, '').trim());

  // Slice the first and last elements and join the remaining elements
  const modifiedAddress = sanitizedAddress.slice(1, -1).join(', ');

  return modifiedAddress;
};

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    isMobileLayout,
    history,
    onViewListing,
    setActiveListing,
  } = props;

  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, description } = currentListing.attributes;

  const slug = createSlug(title);
  const wishlist = getWishlist(currentUser);

  const routeConfiguration = useRouteConfiguration();
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  const location = getPublicDataAttributeValue('location', currentListing);
  const beds = getPublicDataAttributeValue('beds', currentListing);
  const totalGuests = getPublicDataAttributeValue('maxPeople', currentListing) || 0;

  const category = getPublicDataAttributeValue('category', currentListing);
  const categoryLabel =
    category?.length > 1 ? categoryOptions.find(c => c.option === category) : '';

  const images = getProductImages(currentListing.images);

  const onUpdateProfile = params => {
    return dispatch(updateProfile(params));
  };

  const onUpdateCurrentUser = array => {
    const params = { wishlist: Array.from(new Set(array)) };

    return onUpdateProfile({
      publicData: { ...params },
    });
  };

  const guestWord = totalGuests === 1 ? 'Guest' : 'Guests'; // Choose the appropriate word

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <div onClick={() => onViewListing(id)} className={classes} {...setActivePropsMaybe}>
      {isArrayLength(images) ? (
        <ImageSlider
          images={images}
          isMobile={isMobileLayout}
          imgAlt={''}
          pathParams={{ id, slug }}
          pushToListingPage={() => onViewListing(id)}
        />
      ) : null}
      <span
        className={css.favorite}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (isAuthenticated) {
            const index = wishlist && wishlist.findIndex(b => b == id);
            if (id) {
              if (index > -1) {
                wishlist && wishlist.splice(index, 1);
                const removedwishlist = Array.from(new Set(wishlist));
                onUpdateCurrentUser(removedwishlist);
              } else {
                wishlist.push(id);
                onUpdateCurrentUser(Array.from(new Set(wishlist)));
              }
            }
          } else {
            history.push(createResourceLocatorString('LoginPage', routeConfiguration, {}, {}));
          }
        }}
      >
        {isArrayLength(wishlist) && wishlist.findIndex(e => e == id) > -1 ? (
          <IconHeartFilled />
        ) : (
          <IconHeart />
        )}
      </span>
      <div className={css.info}>
        <div className={css.priceSec}>
          <div className={css.featured}>
            <span className={css.tag}>{categoryLabel?.label}</span>
          </div>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}/{' '}
            </div>
            {isBookingProcessAlias(publicData?.transactionProcessAlias) ? (
              <div className={css.perUnit}>
                <FormattedMessage
                  id="ListingCard.perUnit"
                  values={{ unitType: publicData?.unitType }}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className={css.separator} />
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.description}>{getLastElementFromAddress(location?.address)}</div>
          <div className={css.separator} />
          <div className={css.otherInfo}>
            <div className={css.infoBlock}>
              <IconBedroom />
              {beds} Bedrooms
            </div>
            <div className={css.infoBlock}>
              <IconGuest /> {totalGuests} {guestWord}
            </div>
          </div>
          {/* <div className={css.separator} />
          <div className={css.reviews}>
            <div className={css.reviewsLeft}>
              <p>Reviews </p>
              <div className={css.rating}>
                <IconStarFilled />
                <IconStarFilled />
                <IconStarFilled />
                <IconStar />
                <IconStar />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
