import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconPrice = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1873 33.8542H23.2707C20.4999 33.8542 18.229 31.5208 18.229 28.6458C18.229 27.7917 18.9373 27.0833 19.7915 27.0833C20.6457 27.0833 21.354 27.7917 21.354 28.6458C21.354 29.7917 22.2082 30.7292 23.2707 30.7292H27.1873C27.9998 30.7292 28.6457 30 28.6457 29.1042C28.6457 27.9792 28.3332 27.8125 27.6249 27.5625L21.354 25.375C20.0207 24.8958 18.229 23.9375 18.229 20.875C18.229 18.2708 20.2915 16.125 22.8124 16.125H26.729C29.4998 16.125 31.7707 18.4583 31.7707 21.3333C31.7707 22.1875 31.0623 22.8958 30.2082 22.8958C29.354 22.8958 28.6457 22.1875 28.6457 21.3333C28.6457 20.1875 27.7915 19.25 26.729 19.25H22.8124C21.9999 19.25 21.354 19.9792 21.354 20.875C21.354 22 21.6665 22.1666 22.3748 22.4166L28.6457 24.6041C29.979 25.0833 31.7707 26.0417 31.7707 29.1042C31.7707 31.7292 29.7081 33.8542 27.1873 33.8542Z"
        fill="#3e5ba6"
      />
      <path
        d="M25 35.9375C24.1458 35.9375 23.4375 35.2292 23.4375 34.375V15.625C23.4375 14.7708 24.1458 14.0625 25 14.0625C25.8542 14.0625 26.5625 14.7708 26.5625 15.625V34.375C26.5625 35.2292 25.8542 35.9375 25 35.9375Z"
        fill="#3e5ba6"
      />
      <path
        d="M24.9998 47.3959C12.6457 47.3959 2.604 37.3543 2.604 25.0001C2.604 12.6459 12.6457 2.60425 24.9998 2.60425C25.854 2.60425 26.5623 3.31258 26.5623 4.16675C26.5623 5.02091 25.854 5.72925 24.9998 5.72925C14.3748 5.72925 5.729 14.3751 5.729 25.0001C5.729 35.6251 14.3748 44.2709 24.9998 44.2709C35.6248 44.2709 44.2707 35.6251 44.2707 25.0001C44.2707 24.1459 44.979 23.4376 45.8332 23.4376C46.6873 23.4376 47.3957 24.1459 47.3957 25.0001C47.3957 37.3543 37.354 47.3959 24.9998 47.3959Z"
        fill="#3e5ba6"
      />
      <path
        d="M45.8333 14.0626C44.9792 14.0626 44.2708 13.3542 44.2708 12.5001V5.72925H37.5C36.6458 5.72925 35.9375 5.02091 35.9375 4.16675C35.9375 3.31258 36.6458 2.60425 37.5 2.60425H45.8333C46.6875 2.60425 47.3958 3.31258 47.3958 4.16675V12.5001C47.3958 13.3542 46.6875 14.0626 45.8333 14.0626Z"
        fill="#3e5ba6"
      />
      <path
        d="M35.4167 16.1458C35.0208 16.1458 34.625 16 34.3125 15.6875C33.7083 15.0833 33.7083 14.0833 34.3125 13.4792L44.7292 3.0625C45.3333 2.45833 46.3333 2.45833 46.9375 3.0625C47.5417 3.66667 47.5417 4.66666 46.9375 5.27083L36.5208 15.6875C36.2083 16 35.8125 16.1458 35.4167 16.1458Z"
        fill="#3e5ba6"
      />
    </svg>
  );
};

IconPrice.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPrice.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPrice;
