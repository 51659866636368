import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconBooking = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6665 11.9792C15.8123 11.9792 15.104 11.2709 15.104 10.4167V4.16675C15.104 3.31258 15.8123 2.60425 16.6665 2.60425C17.5207 2.60425 18.229 3.31258 18.229 4.16675V10.4167C18.229 11.2709 17.5207 11.9792 16.6665 11.9792Z"
        fill="#3e5ba6"
      />
      <path
        d="M33.3335 11.9792C32.4793 11.9792 31.771 11.2709 31.771 10.4167V4.16675C31.771 3.31258 32.4793 2.60425 33.3335 2.60425C34.1877 2.60425 34.896 3.31258 34.896 4.16675V10.4167C34.896 11.2709 34.1877 11.9792 33.3335 11.9792Z"
        fill="#3e5ba6"
      />
      <path
        d="M17.7083 30.2082C17.1667 30.2082 16.625 29.9791 16.2292 29.604C15.8542 29.2082 15.625 28.6874 15.625 28.1249C15.625 27.8541 15.6875 27.5832 15.7917 27.3332C15.8958 27.0832 16.0417 26.8541 16.2292 26.6458C17 25.8749 18.3958 25.8749 19.1875 26.6458C19.5625 27.0416 19.7917 27.5832 19.7917 28.1249C19.7917 28.2499 19.7708 28.3958 19.75 28.5416C19.7292 28.6666 19.6875 28.7916 19.625 28.9166C19.5833 29.0416 19.5208 29.1666 19.4375 29.2916C19.3542 29.3957 19.2708 29.4999 19.1875 29.604C18.7917 29.9791 18.25 30.2082 17.7083 30.2082Z"
        fill="#3e5ba6"
      />
      <path
        d="M24.9998 30.2083C24.729 30.2083 24.4582 30.1459 24.2082 30.0417C23.9373 29.9375 23.729 29.7916 23.5207 29.6041C23.1457 29.2083 22.9165 28.6875 22.9165 28.125C22.9165 27.8542 22.979 27.5833 23.0832 27.3333C23.1873 27.0833 23.3332 26.8542 23.5207 26.6458C23.729 26.4583 23.9373 26.3125 24.2082 26.2083C24.979 25.8958 25.8957 26.0625 26.479 26.6458C26.854 27.0417 27.0832 27.5833 27.0832 28.125C27.0832 28.25 27.0623 28.3959 27.0415 28.5417C27.0207 28.6667 26.979 28.7917 26.9165 28.9167C26.8748 29.0417 26.8123 29.1667 26.729 29.2917C26.6665 29.3958 26.5623 29.5 26.479 29.6041C26.0832 29.9791 25.5415 30.2083 24.9998 30.2083Z"
        fill="#3e5ba6"
      />
      <path
        d="M17.7083 37.5001C17.4375 37.5001 17.1667 37.4376 16.9167 37.3335C16.6458 37.2293 16.4167 37.0834 16.2292 36.8959C16.0417 36.7084 15.8958 36.4793 15.7917 36.2085C15.6875 35.9585 15.625 35.6876 15.625 35.4168C15.625 35.146 15.6875 34.8751 15.7917 34.6251C15.8958 34.3543 16.0417 34.1251 16.2292 33.9376C16.4167 33.7501 16.6458 33.6043 16.9167 33.5001C17.4167 33.2918 18 33.2709 18.5 33.5001C18.7708 33.6043 19 33.7501 19.1875 33.9376C19.375 34.1251 19.5208 34.3543 19.625 34.6251C19.7292 34.8751 19.7917 35.146 19.7917 35.4168C19.7917 35.6876 19.7292 35.9585 19.625 36.2085C19.5208 36.4793 19.375 36.7084 19.1875 36.8959C19 37.0834 18.7708 37.2293 18.5 37.3335C18.25 37.4376 17.9792 37.5001 17.7083 37.5001Z"
        fill="#3e5ba6"
      />
      <path
        d="M42.7082 20.5H7.2915C6.43734 20.5 5.729 19.7917 5.729 18.9375C5.729 18.0833 6.43734 17.375 7.2915 17.375H42.7082C43.5623 17.375 44.2707 18.0833 44.2707 18.9375C44.2707 19.7917 43.5623 20.5 42.7082 20.5Z"
        fill="#3e5ba6"
      />
      <path
        d="M37.4998 49.4792C35.0623 49.4792 32.7498 48.6042 30.979 47C30.229 46.375 29.5623 45.5833 29.0207 44.6667C28.104 43.1667 27.604 41.3958 27.604 39.5833C27.604 34.125 32.0415 29.6875 37.4998 29.6875C40.3332 29.6875 43.0415 30.9166 44.9165 33.0416C46.5207 34.875 47.3957 37.1875 47.3957 39.5833C47.3957 41.3958 46.8957 43.1667 45.9582 44.6875C44.2082 47.6459 40.9582 49.4792 37.4998 49.4792ZM37.4998 32.8125C33.7707 32.8125 30.729 35.8542 30.729 39.5833C30.729 40.8125 31.0623 42.0209 31.7082 43.0625C32.0623 43.6875 32.5207 44.2083 33.0207 44.6458C34.2707 45.7708 35.8332 46.3542 37.4998 46.3542C39.8957 46.3542 42.0623 45.125 43.2915 43.0833C43.9373 42.0208 44.2707 40.8333 44.2707 39.5833C44.2707 37.9583 43.6665 36.375 42.5832 35.1042C41.2915 33.6458 39.4373 32.8125 37.4998 32.8125Z"
        fill="#3e5ba6"
      />
      <path
        d="M36.3122 43.2083C35.9163 43.2083 35.5205 43.0625 35.208 42.75L33.1455 40.6875C32.5413 40.0833 32.5413 39.0833 33.1455 38.4791C33.7497 37.875 34.7497 37.875 35.3538 38.4791L36.3538 39.4792L39.6872 36.3958C40.3122 35.8125 41.3122 35.8542 41.8955 36.4792C42.4788 37.1042 42.4372 38.1042 41.8122 38.6875L37.3747 42.7917C37.0622 43.0625 36.6872 43.2083 36.3122 43.2083Z"
        fill="#3e5ba6"
      />
      <path
        d="M32.0208 47.3959H16.6667C9.0625 47.3959 4.6875 43.0209 4.6875 35.4167V17.7084C4.6875 10.1042 9.0625 5.72925 16.6667 5.72925H33.3333C40.9375 5.72925 45.3125 10.1042 45.3125 17.7084V34.0834C45.3125 34.7292 44.9167 35.3126 44.2917 35.5417C43.6875 35.7709 43 35.6043 42.5625 35.1043C41.2708 33.6459 39.4167 32.8126 37.4792 32.8126C33.75 32.8126 30.7083 35.8542 30.7083 39.5834C30.7083 40.8126 31.0417 42.0209 31.6875 43.0626C32.0417 43.6876 32.5 44.2084 33 44.6459C33.5 45.0626 33.6875 45.7501 33.4583 46.3751C33.2708 46.9793 32.6875 47.3959 32.0208 47.3959ZM16.6667 8.85425C10.7083 8.85425 7.8125 11.7501 7.8125 17.7084V35.4167C7.8125 41.3751 10.7083 44.2709 16.6667 44.2709H28.7917C28.0208 42.8543 27.6042 41.2501 27.6042 39.5834C27.6042 34.1251 32.0417 29.6876 37.5 29.6876C39.1458 29.6876 40.7708 30.1043 42.1875 30.8751V17.7084C42.1875 11.7501 39.2917 8.85425 33.3333 8.85425H16.6667Z"
        fill="#3e5ba6"
      />
    </svg>
  );
};

IconBooking.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBooking.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBooking;
