/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `OrderBreakdown` component.
 */
import React, { useState } from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';
import { Tooltip } from 'react-tooltip';

import '../../styles/react-tooltip.css';
import css from './OrderBreakdown.module.css';
import moment from 'moment';

const LineItemUnknownItemsMaybe = props => {
  const { lineItems, isProvider, intl, booking } = props;

  const start = booking?.attributes?.start;
  const end = booking?.attributes?.end;
  const bookingStart = moment(start);
  const bookingEnd = moment(end);
  const differenceInDays = bookingEnd.diff(bookingStart, 'days');

  // resolve unknown non-reversal line items
  const allItems = lineItems.filter(item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal);

  const items = isProvider
    ? allItems.filter(item => item.includeFor.includes('provider'))
    : allItems.filter(item => item.includeFor.includes('customer'));

  return items.length > 0 ? (
    <React.Fragment>
      {items.map((item, i) => {
        const quantity = item.quantity;

        const label =
          quantity && quantity > 1
            ? `${humanizeLineItemCode(item.code)} x ${quantity}`
            : humanizeLineItemCode(item.code);

        const formattedTotal = formatMoney(intl, item.lineTotal);
        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            <span className={css.itemLabel}>
              {!isProvider && label === 'Service fee' ? (
                <a
                  data-tooltip-id="serviceFee"
                  data-tooltip-content="This fee helps BnBunite to provide services like 24/7 support, and to fund our rewards and scholarship programs. #NoCorporateGreed"
                >
                  Service fee
                </a>
              ) : label === 'Transaction fee' ? (
                'Transaction fee (3%)'
              ) : label === 'Insurance fee' ? (
                'Insurance (Property Damage)'
              ) : label.includes('Liability') ? (
                <a
                  data-tooltip-id="liabilityCoverage"
                  data-tooltip-content="Hopefully it’s not needed, but this fee covers the host property if an accident occurs. #NoCorporateGreed"
                >
                  Liability Coverage x {differenceInDays} Nights
                </a>
              ) : (
                label
              )}
            </span>
            <span className={css.itemValue}>{formattedTotal}</span>
          </div>
        );
      })}
      <Tooltip variant="light" openOnClick id="serviceFee" />
      <Tooltip variant="light" openOnClick id="liabilityCoverage" />
    </React.Fragment>
  ) : null;
};

LineItemUnknownItemsMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
