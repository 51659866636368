import React, { useState } from 'react';
import { isUserEmailVerified } from '../../helpers/dataExtractors';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink, InlineTextButton, Modal } from '..';
import { selectVerificationEmailInProgress, sendVerificationEmail } from '../../ducks/user.duck';
import { useDispatch, useSelector } from 'react-redux';
import css from './EmailVerificationModal.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function EmailVerificationModal(props) {
  const {
    currentUser,
    isAuthenticated,
    onManageDisableScrolling,
    isEditListingPage = false,
    setShowVerificationModal = () => {},
  } = props;
  
  const dispatch = useDispatch();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const sendVerificationEmailInProgress = useSelector(selectVerificationEmailInProgress);


  return (
    <div>
      {!currentUser
        ? null
        : !isUserEmailVerified(currentUser) &&
          isAuthenticated && (
            <Modal
              id="EmailVerificationModal.isUserEmailVerified"
              isOpen={!isUserEmailVerified(currentUser)}
              onClose={() => {
                if (isEditListingPage) {
                  history.push('/');
                } else {
                  setShowVerificationModal(false);
                }
                }}
              onManageDisableScrolling={onManageDisableScrolling}
              usePortal
            >
              <div className={css.verifiedEmailContainer}>
                <h4>
                  <FormattedMessage id="Topbar.isUserEmailVerified" />
                </h4>
                <p className={css.verifiedEmailContent}>
                  <FormattedMessage id="Topbar.isUserEmailVerifiedContent" />
                  <br />
                  <div className={css.emailLogos}>
                    <ExternalLink href="https://mail.google.com">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACv0lEQVR4nO2UT2jTYByG48F5c4ieJ47RBE0rbZItcT2VHYQWWoebx11ksEPPehC92YA35wSvnpyDVoUetNrmYGvRKiK7bG6oW77uIqOCcyVx/eSbjWY1af70r/C98N7Kr++TPC1B4ODg4ODg6FLyeE7kKepugSTLeZLcyZNkseDxTBNdzk+JnlYluqjk6B0l5y2rOfoOzFDHrceT5GqBoqBBl0rDw4OdHg4zzKAi0Uuq5IX/NEevNIXYf/LG47WuFSmK6dR4JetlVMm7Zjj+b+fNAUhyywIAtQp4Lt7u8YDn4orkrVqMh0qOBuYAFFWzAQCBwEGZ5x59CQaPtTr86+joUSBwD9BNq/G/Abw102N2xmsAdYjPm+OjvNvxgGcCQOA+avfsAKC2DaBeBQjcFUgQh5yMLwvsLOC5qv5WrwAcKaVXBjS0ZYD5iZn3bgHsKNWoDHAB8Doz9sYUYEgEcHJuUc3RZ6tuAJopZaQMcADwI+vbvZUOVSKpGGwKgBq49g7eD8b2XAIcUKqZMsAmwPrzQPXy4zBE420BoJ66uQGvXrxeM/prtTOmDrGOavfzqsH49NNxeCEV/TPeNoDWybnFmnTGt+sGwGlV3fDvWZ8ipicODHcFoCmVYkNb3QLYfOHf1ivTMoCmVCIcX0FKdRLg5TP+U6MybQHQOjuz8AEIXKUDAJWFJ6HlZsPbAoBaFvwnAc++atd4WeDebvD+ETvj2wKAPltimMMyz4qywLWklMxz91bPjxxBN7sKoAWcY6KAZ7edP3X2mzzGXtLf6gkAilOlNGWIhvQMwIlSemX6CsBKKSNl+hLASCkzZfoWAAVpIgvsbVQzZfoawE0iGEDEb6ClRLBCCQD6/kecjMmmB4ZEWex3gHAqmjA9cPrG8sA+hMWb6AVAOBkDkWRMnHo4NdCp78fBwcHBIf67/ALECpwyy5gUBAAAAABJRU5ErkJggg==" />
                    </ExternalLink>
                    <ExternalLink href="https://outlook.live.com">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeklEQVR4nO3UMQqAMBBE0QUL7+F5LOxyxlSewTvZj/10Q8AI/gfbBpb8pAoAPklt08j4eft5aGSKBRo3IBJK8IgNv1CKhAwJpUjIkFCKhGYnBPzd0m+NjJ+na9XIFAt0bkAklOARG36hFAkZEkqRkCGhFAnNTggA6g0PsZwml5fu+o4AAAAASUVORK5CYII=" />
                    </ExternalLink>
                    <ExternalLink href="https://mail.yahoo.com">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVR4nO2YTWgTQRTHn98f+HUoRbAg+AV+oEI02Xkb2CIeFAUvRkGLhZq+SYJVBA/qpQexFy8qelNB8KC9KG3yXmLBHBS0WBDBi1BQsSAKIiLiZ61sD6Wd3SBp02RX8oNlDzvvP/OfnZ33ZgHq1Knz/6ORbxByv0buG70r+UBK9lRKnzC3TSt+Q8h5QrmplbBGvlIpfSDk1VrxN40yMnYpGezYxfOmqt0JnTNJyeMJ2ig/kvHcOqgkpLjL6GSEFJ+eqq62+aipqxWfh0pDkZ6FhPLaMPBFW70rJqvZEeUlhPLOMPA24xQXwXRAmDvofQtya7J6Wsllz+zbcqCyo/Z0yg8mdsp/2i12ytVJ2rKRFP8yvquHACMzpmfk4zrWyD+Njp8lEt2zytEhxUVD43c6xpuhGmjkS56lhJwuI/6wT/xFqBb+Hx9/JKen4V+xbXhvMaEMGYN/3+rcXQbVxG/7I5Sr/4ojlAtmXEpxG1QbNwFpJU/MdZzE7NZSMTp2fy0p+W5sxQOuFtSCdLwQIZRh4y08KrWTaGQx2g5TPBuDWkIo1z1LycofMttpxft9csg1qDUpVWjUSj4ZMzs0PpuetLoXEPIro83nzPbscggCWskJnzqpa+w58jmf2T8OQcFNYoTy3K+i9K9k+QVFBuZAkNC2NHvqGuQsIff61DvNEERI8R2vCbNUltsQVJJRbhotsUsb+JpR2ZUQZAjlbEkDtpyBoJPY0D1XK3nps3QGW53ifAgDKZXf67NtVuwnwLTT6RRnmwbKPS/UFDcLmwbcbAxhgZyeBs8SivQthbCQjHKTt+YvNEJYyFj5NaYB1xSEhZQqbDINpOPZVRAWyP3XaRqIZddDWCCb494snN8CYaHdkp0eA1Y+CmEh5ZeJbY5DWNA+59+Uyu2AsEDILaaBdju3G8ICoSR9yul9EBa0nT/mU40mICxoxac8BpBbIEwGCLnfPSOPnpOVPNVW7kitx1WnTh0IHn8BJ7HrZNGtN6MAAAAASUVORK5CYII=" />
                    </ExternalLink>
                  </div>
                </p>
                <InlineTextButton
                  rootClassName={css.helperLink}
                  inProgress={sendVerificationEmailInProgress}
                  ready={ready}
                  onClick={async () => {
                    setReady(false);
                    await dispatch(sendVerificationEmail());
                    setReady(true);
                  }}
                >
                  <FormattedMessage id="ModalMissingInformation.resendEmailLinkText" />
                </InlineTextButton>
              </div>
            </Modal>
          )}
    </div>
  );
}

export default EmailVerificationModal;
