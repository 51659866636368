import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../../components';

import css from './SignupHostForm.module.css';
import { debounce } from 'lodash';

const SignupHostFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        values,
        form,
      } = fieldRenderProps;

      const [confirmPassword, setConfirmPassword] = useState('');
      const [isPasswordSame, setIsPasswordSame] = useState(null);

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const onCheckPassword = value => {
        setIsPasswordSame(value === values?.password);
      };

      const debouncedOnCheckPassword = debounce(onCheckPassword, 3000);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.formRow}>
            <div className={css.formFld}>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                // label={intl.formatMessage({
                //   id: 'SignupForm.emailLabel',
                // })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
            </div>
          </div>
          <div className={classNames(css.formRow, css.halfWidth)}>
            <div className={css.formFld}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                // label={intl.formatMessage({
                //   id: 'SignupForm.firstNameLabel',
                // })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.firstNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.firstNameRequired',
                  })
                )}
              />
            </div>
            <div className={css.formFld}>
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                // label={intl.formatMessage({
                //   id: 'SignupForm.lastNameLabel',
                // })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.lastNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.lastNameRequired',
                  })
                )}
              />
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formFld}>
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                // autoComplete="new-password"
                // label={intl.formatMessage({
                //   id: 'SignupForm.passwordLabel',
                // })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formFld}>
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.confirmPassword` : 'confirmPassword'}
                name="confirmPassword"
                onChange={e => {
                  debouncedOnCheckPassword(e.target.value);
                  form.change('confirmPassword', e.target.value);
                }}
                onFocus={e => {
                  const value = e.target.value;
                  setConfirmPassword(value);
                  form.change('terms', null);
                }}
                value={confirmPassword}
                // autoComplete="new-password"
                // label={intl.formatMessage({
                //   id: 'SignupForm.passwordLabel',
                // })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.confirmPasswordPlaceholder',
                })}
                validate={passwordValidators}
              />
            </div>
          </div>
          {!isPasswordSame && values?.confirmPassword?.length >= 8 ? (
            <p className={css.passwordError}>Passwords do not match.</p>
          ) : null}

          <div className={css.formRow}>
            <div className={css.formFld}>
              {termsAndConditions}
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={!isPasswordSame ? true : submitDisabled}
              >
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

SignupHostFormComponent.defaultProps = { inProgress: false };

SignupHostFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupHostForm = compose(injectIntl)(SignupHostFormComponent);
SignupHostForm.displayName = 'SignupHostForm';

export default SignupHostForm;
