export const getSubscriptionPlanName = user => {
  return user && user.id && user?.attributes?.profile?.protectedData?.planName;
};

export const getSubscription = user => {
  return user && user.id && user?.attributes?.profile?.protectedData?.subscriptionPlan;
};
export const getDisplayName = user => {
  return user && user.id && user?.attributes?.profile?.displayName;
};

export const getTxListingName = tx => {
  return tx && tx.id && tx?.listing?.attributes?.title;
};
export const getTxBookingState = tx => {
  return tx && tx.id && tx?.booking?.attributes?.state;
};

export const getUserType = user => {
  return user && user.id && user?.attributes?.profile?.protectedData?.type;
};

export const getBookableDates = listing => {
  return listing && listing.id && listing?.attributes?.publicData?.bookableDates;
};
export const getWeekendAmount = listing => {
  return listing && listing.id && listing?.attributes?.publicData?.weekendAmount;
};
export const isPetsAllowed = listing => {
  return listing && listing.id && listing?.attributes?.publicData?.petsAllowed === 'yes';
};

export const getTransactionBookingStart = tx => {
  return tx && tx.id && tx?.booking?.attributes?.start;
};

export const getPersonaFieldsParams = user => {
  const emailAddress = user?.attributes?.email;
  const nameFirst = user?.attributes?.profile?.firstName;
  const nameLast = user?.attributes?.profile?.lastName;
  const id = user?.id.uuid;

  return { nameFirst, nameLast, emailAddress, id };
};

export const getIdentityStatus = user => {
  return (
    user &&
    user.id &&
    user?.attributes?.profile?.protectedData?.identityMetadata?.verification_status
  );
};

export const isUserEmailVerified = user => {
  return user?.id && user?.attributes?.emailVerified;
};

export const isUserIdentitySubmitted = user => {
  return user?.id && user?.attributes?.profile?.publicData?.identitySubmitted;
};
