import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import IconPrevArrow from '../IconPrevArrow/IconPrevArrow';
import IconNextArrow from '../IconNextArrow/IconNextArrow';
import { Instagram } from 'react-content-loader';
import NamedLink from '../NamedLink/NamedLink';

import '../../styles/slick/slick.css';
import '../../styles/slick/slick-theme.css';
import css from './ImageSlider.module.css';
import { isArrayLength } from '../../util/genericHelpers';

function ImageSlider(props) {
  const {
    images,
    isMobile,
    pushToListingPage = () => {},
    isSectionHero = false,
    imgAlt = '',
    pathParams = {},
  } = props;

  const handleDragStart = e => e.preventDefault();

  const location = useLocation();

  let items =
    images &&
    images.map((item, index) => (
      <img
        key={index}
        src={item}
        alt={imgAlt}
        onDragStart={handleDragStart}
        role="presentation"
        className={classNames({
          [css.images]: !isSectionHero,
          [css.heroImages]: isSectionHero,
        })}
        onClick={() => pushToListingPage(pathParams?.id)}
      />
    ));

  if (pathParams?.id) {
    items =
      images &&
      images.map((item, index) => (
        <div onClick={() => pushToListingPage(pathParams?.id)} key={index}>
          {items[index]}
        </div>
      ));
  }

  const isLandingPage = location && location.pathname === '/';

  // State to keep track of whether component is mounted
  const [isMounted, setIsMounted] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  // Cancel subscriptions and asynchronous tasks when component is unmounted
  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconNextArrow className={css.icon} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconPrevArrow className={css.icon} />
      </div>
    );
  }

  const settings = {
    dots: isSectionHero ? false : true,
    fade: true,
    arrows: isHovered,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: false,
    speed: 800,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  if (isMobile) {
    settings.arrows = false;
  }

  return (
    <div
      className={css.carouselContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isMounted && (
        <Slider className={css.cardSlider} {...settings}>
          {isArrayLength(items) ? items : <Instagram />}
        </Slider>
      )}
    </div>
  );
}

export default ImageSlider;
