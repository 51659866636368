import React from 'react';
import css from './TermsOfServicePage.module.css';

const TermsOfServiceContent = () => {
  return (
    <div className={css.content}>
      <h1>Terms of Service</h1>

      <p>
        This site is a marketplace platform that connects users. BnBunite is not a party to any
        rental agreement or any transaction between the users of this site. We advise all users to
        be responsible for their actions on this platform and any transaction agreement entered into
        as a result of any property listing on this platform. BnBunite is not an acting agent for
        any user. BnBunite doesn’t own any property on the platform. All hosts are required to
        comply with all laws pertaining to hosting your property in your jurisdiction. We offer a
        platform texting tool to enable users to communicate with each other. There may be
        situations when BnBunite may be legally obligated to comply with governmental bodies in
        relation to investigations when it relates to users on this platform. BnBunite will take
        many precautions to prevent illegal activity against our users.
      </p>

      <p>
        <strong>2. User Conduct </strong> <br /> All users are required to always be respectful to
        other users and BnBunite staff or lose the privilege to use this platform.
      </p>
      <p>
        <strong>3. Your Email</strong> <br /> When you provide your email address you agree that
        BnBunte and its affiliates may add your email to its user database, and you may receive some
        promotional emails each month.
      </p>
      <p>
        <strong>4. Payments</strong> <br /> To avoid scams and the loss of your funds, it is always
        recommended that you keep all payment on this platform. Using other services to transfer
        funds will leave you with no recourse in the event of fraud.
      </p>
      <p>
        <strong>5. Host/Refunds</strong> <br /> Host final payment will be transferred to the bank
        account you added to your account (3) days after the reservation is finished, but the first
        payment transaction could take between 2-3 weeks. Refunds are issued 3-5 days after
        initiated, the final determination of when the funds will be available relies on your
        banking institution.
      </p>
      <p>
        <strong>6. Photos/Images</strong> <br /> You recognize that by posting images on this
        platform, grants BnBunite the rights to use your property images for advertising, marketing,
        commercials and for other purposes in any media or other platform. Property images are to be
        of the property you are listing on the platform. Property images are to be clear of people,
        and any controversial images. BnBunite has the authority to remove any images it deems
        inappropriate. All pictures submitted to this platform gives BnBunite the rights to use the
        likeness for marketing purposes on other platforms. All images go through a verification
        process that could take up to 48 hours. Users agree that they have the rights to all images
        they post on this platform.
      </p>
      <p>
        <strong>7. Profile Picture</strong> <br /> All users are required to have a current and
        clear headshot profile picture. Users’ selfie picture will become their default profile
        picture. The default picture can be changed by the user.
      </p>
      <p>
        <strong>8. Insurance</strong> <br /> All rentals come with $3,000 in property damage for the
        duration of the rental, and $100,000 in liability. The amount of $7.00 per day will be
        deducted from the host funds before final payment for property damage. And guests will be
        charged $5.00 per day for liability insurance. For damage/liability claims funds will be
        transferred 30 days after BnBunite has received a police report, after BnBunite does its own
        investigation, and all other channels have been exhausted by the host to be made whole.
        BnBunite will then determine the validity of the claim. An actual short-term rental property
        policy (Is Recommended).
      </p>
      <p>
        <strong>9. Fees</strong> <br />
        <strong>Guests</strong> <br />
        <ul>
          <li>• Are charged a fixed 4% service fee when booking a property. </li>
          <li>• Are charged $5.00 per day for liability Coverage. </li>
        </ul>
        <strong>Hosts</strong> <br />
        <ul>
          <li>• Are charged a 3% transaction fee.</li>
          <li>• Are charged $7.00 per day for property damage coverage.</li>
        </ul>
      </p>
      <p>
        <strong>10. Cancellation Policy</strong> <br />{' '}
        <span style={{ textDecoration: 'underline' }}>
          BnBunite offers a standard cancellation policy for all host.
        </span>
        <ul>
          <li>
            • Guests get a 100% refund if they cancel at least 30 days before their check-in date.
          </li>
          <li>
            • If guests cancel 14 days before check-in, they get a 50% refund (excluding the service
            fee).
          </li>
          <li>• Cancellations less than 14 days before check-in are non-refundable.</li>
          <li>• Guest will receive a 100% refund if the host cancels at any point.</li>
        </ul>
      </p>
      <p>
        <strong> 10.2 Host/Property Inaccuracies</strong> <br />{' '}
        <span style={{ textDecoration: 'underline' }}>
          Guests will have the option to ask for a refund if:{' '}
        </span>
        <ul>
          <li>
            • The host becomes unresponsive or a no show at check-in if the property is not
            self-check-in.
          </li>
          <li>• The property does not match the listing description that’s on the website.</li>
          <li>• The property contains safety or health hazards.</li>
          <li>• The property contains pests.</li>
          <li>• The special amenities are non-functionable.</li>
          <li>• The property is not reasonably clean.</li>
        </ul>
      </p>
      <p>
        <strong> 10.3 If a Host Cancels a Confirmed Reservation:</strong> <br />
        <ul>
          <li>
            • If a reservation is canceled more than 30 days before check-in the host will be
            charged 15% of the reservation amount.
          </li>
          <li>
            • If the reservation is canceled between 72 hours and 30 days before check-in the host
            will be charged 25% of the reservation amount.
          </li>
          <li>
            • If a reservation is canceled less than 72 hours of check-in the host will be charged
            60% of the reservation amount.
          </li>
          <li>
            • If a reservation is more than 14 days, the percentage charged to the host will be
            based on the amount of the reservation.
          </li>
          <li>
            • If the host becomes known for canceling confirmed reservations, the host will be
            banned from hosting the property or properties on the platform.
          </li>
          <li>
            • If a host cancels a confirmed reservation during a reservation for anything other than
            guests breaking house rules, the host/property will be banned from the platform.
          </li>
          <li>
            • The host payment method on file will be charged for all violations. If BnBunite is
            unable to retrieve the funds, the host and all properties will be banned indefinitely.
          </li>
          <li>
            • In some cases, the host cancellation fees will be held from their next reservation.{' '}
          </li>
          <li>• The minimum cancellation fee is $40.</li>
          <li>
            • After a cancellation by the host, their booking calendar may be blocked on those
            canceled days for that property.{' '}
          </li>
        </ul>
      </p>
      <p>
        <strong> 11. Check-in/Check-out </strong> <br />
        All host will determine their own check-in and check-out times for their property. If a
        guest wants to check-in earlier or check-out later, the guest must get an approval from the
        host. After getting an approval from the host, the guests may be charged an extra fee by the
        host.
      </p>
      <p>
        <strong> 12. Cleaning Fees </strong> <br />
        No cleaning fees for all reservations.
      </p>
      <p>
        <strong> 13. Hosting Posting Fees </strong> <br />
        All host will be able to post their properties for <strong>free.</strong>
      </p>
      <p>
        <strong> 14. Subscription Fee </strong> <br />
        BnBunite does <strong>Not</strong> charge a subscription fee.
      </p>
      <p>
        <strong> 15. Commission Fee </strong> <br />
        BnBunite does <strong>Not</strong> charge a commission fee.
      </p>
      <p>
        <strong> 16. Pet Fee </strong> <br />
        BnBunite does <strong>Not</strong> charge pet fees.
      </p>
      <p>
        <strong> 17. Identity Verification </strong> <br />
        All users are required to email a picture of the front & back of their unexpired government
        ID and a selfie to (idverify@BnBunite.com). Both images must match each other. The name on
        the ID must match the name of the users account on the platform. All images go through a
        verification process that could take between 3-5 days. Once verified, users will be able to
        post properties and place reservations.
      </p>
      <p>
        <strong> 18. Rewards Program</strong> <br />
        <br />
        Users that have completed a reservation for that month are automatically entered to win cash
        rewards for that month. The winners will be randomly selected from a completed reservation
        for that month. Their will be multiple winners each month.
        <br />
        Prize amounts are:
        <ul>
          <li>• $1,000</li>
          <li>• $2,500</li>
          <li>• $5,000</li>
          <li>• $10,000</li>
        </ul>
        The prize amount will be split between the host and guest. Winners are required to send a
        clear full body picture. Your picture and first name may be used by BnBunite on other
        platforms for marketing purposes indefinitely. The prize money may be in the form of a check
        that is mailed to the address on file or transferred to the payment method on file at the
        time of the drawing. Prize money is non-transferable. All guests will receive a 10% discount
        at booking when compared to Airbnb & VRBO.
      </p>
      <p>
        <strong>19. College Scholarship Program </strong> <br />
        BnBunite’s scholarship program will only be rewarded to users that have completed a booking
        on the platform. The scholarship funds can be transferred to another individual if the user
        does not have any school debt. If transferring your eligibility to another person, their
        essay must accompany a notarized letter from the eligible user, indicating why you’re
        transferring your eligibility to this person.
        <br />
        <br />
        <strong> 19.1 Essay must be/have: </strong>
        <ul>
          <li>• 500-1,000 words (about you, and why you)</li>
          <li>• In APA format </li>
          <li>• In PDF format </li>
          <li>• School or loan holder name and acct# must be in the email. </li>
          <li>• Your address in the email. </li>
          <li>• Emailed to: Scholarship@BnBunite.com </li>
        </ul>
      </p>
      <p>
        <strong> 19.2 How Funds are Delivered </strong> <br />
        BnBunite will mail a check to the randomly selected winner. The check will be indorsed to
        the educational institution, or to your loan holder with your name and account number in the
        memo section.
      </p>
      <p>
        <strong> 19.3 Scholarship Amounts </strong> <br />
        <ul>
          <li>• $1,000</li>
          <li>• $2,000</li>
          <li>• $5,000</li>
          <li>• $10,000</li>
        </ul>
      </p>
      <p>
        <strong> 19.4 Scholarship Winners </strong> <br />A winner can be selected no more than (3)
        times in a calendar year. Scholarship winners must submit a clear picture of themselves
        before funds are released. BnBunite retain the rights to all images/photos submitted for
        advertising purposes on social media platforms.
      </p>
      <p>
        <strong> 20. Guests Responsibility </strong> <br />
        Most of the time, you stay at your host's primary home. All guest must therefore treat the
        host's household with respect, the same way you would like guests to treat your home, caring
        for your host's home as if it were your own. By opening their home, is placing a lot
        of faith in you. As a guest, it is therefore important to do your utmost to thank your host
        for their generosity by taking care of their home. You should leave the house in the same
        condition you found it when you arrived.
        <br />
        <br />
        <strong>Cleaning Tips</strong> <br />
        <li> Clean each room before your departure. </li>
        <li> Empty the refrigerator if necessary.</li>
        <li> Make the bed with clean sheets if available.</li>
        <li> Empty all trash cans.</li>
        <li> Put used sheets and towels away.</li>
        <br />
        Before check-in, you and your host should agree on the household responsible for your stay.
        Normally, the guest is responsible for leaving the house as they found it. It’s the guest’s
        responsibility to perform the different household chores agreed upon with the host.
      </p>
      <p>
        <strong>21. Host Responsibilities</strong> <br />
        <strong>
          When a guest arrives at a BnBunite property, which may vary depending on the specific
          circumstances and location. Here are some common responsibilities a host should consider:
        </strong>
        <li>
          Provide a warm welcome: A host should greet the guest in person if possible, introduce
          themselves, and give a friendly welcome. This can help set a positive tone for the guests’
          stay and make them feel comfortable and welcomed.
        </li>
        <li>
          Give a property tour if possible: A host should show the guest around the property and
          explain how to use any appliances, electronics, or amenities available. This can help the
          guest settle in and feel at home.
        </li>
        <li>
          Provide necessary information: A host should provide the guest with any necessary
          information about the property, such as emergency contact information, instructions for
          check-out, and the Wi-Fi password. The host should also inform the guest about any rules
          or policies, such as quiet hours.
        </li>
        <li>
          Address any concerns: If the guest has any concerns or questions about the property, the
          host should address them promptly and professionally. This can help the guest feel more
          comfortable and ensure their stay is enjoyable.
        </li>
        <li>
          Be available for assistance: A host should be available for assistance during the guest's
          stay in case of emergencies.{' '}
        </li>
      </p>
      <p>
        <strong> 22. Logo/Use of Our Name</strong> <br />
        BnBunite does <strong>Not</strong> give anyone permission to use its logo for any purpose.
        You may <strong>Not</strong> use the BnBunite name in a way to mislead someone in to
        believing you are sponsored by BnBunite. Without BnBunite permission.
      </p>
      <p>
        <strong> 23. Prohibitions </strong> <br />
        <ul>
          <li>• Modifying or copying materials. </li>
          <li>• Commercial use or public display. </li>
          <li>• Reverse engineering software. </li>
          <li>• Removing copyright marks. </li>
          <li>• Transferring or mirroring content. </li>
        </ul>
      </p>
      <p>
        <strong> 24. Acceptance </strong> <br />
        <ul>
          <li>Accessing this website means agreeing to these terms. </li>
          <li>Follow all laws. </li>
          <li>Content is protected by copyright and trademark laws. </li>
        </ul>
      </p>
      <p>
        <strong> 25. Use of License </strong> <br />
        <ul>
          <li>
            {' '}
            <strong> License Type: </strong>  Limited, non-exclusive, and revocable.
          </li>
          <li>
            {' '}
            <strong> Usage:  </strong> Personal, non-commercial view.
          </li>
        </ul>
      </p>
      <p>
        <strong> 26. Disclaimer</strong>

        <br />
        <ul>
          <li>BnBunite does not own or manage any property that is listed on this platform. </li>
          <li>The listing host sets prices, fees, and check-in/check-out times etc.</li>
        </ul>
      </p>
      <p>
        <strong> 27. Liability</strong>

        <br />
        <ul>
          <li>BnBunite holds no liability for damages from using the website.</li>
        </ul>
      </p>
      <p>
        <strong> 28. Revisions</strong>

        <br />
        <ul>
          <li>Materials may have errors.</li>
          <li>Content can change without notice.</li>
        </ul>
      </p>
      <p>
        <strong> 29. Links</strong>

        <br />
        <ul>
          <li>Not responsible for external links.</li>
          <li>No endorsements implied.</li>
        </ul>
      </p>
      <p>
        <strong> 30. Modifications</strong>

        <br />
        <ul>
          <li>Terms can change without notice.</li>
        </ul>
      </p>
      <p>
        <strong> 31. Privacy</strong>

        <br />
        <ul>
          <li>See our Privacy Policy for data handling details.</li>
        </ul>
      </p>
      <div className={css.footer}>
        <p>
          {' '}
          <strong>Governing Law</strong>
        </p>
        <p>Governed by U.S. Laws</p>
      </div>
    </div>
  );
};

export default TermsOfServiceContent;
