import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { transitions } from '../../transactions/transactionProcessBooking';
import { parse } from '../../util/urlHelpers';
// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_ERROR';

// Cancellation Transitions
const CancellationTransitions = {
  CANCEL_AS_ADMIN: 'transition/cancel-as-admin',
  CANCEL_AS_HOST: 'transition/cancel-as-host',
  CANCEL_AS_CUSTOMER: 'transition/cancel-as-customer',
  FULL_CANCELLATION: 'transition/full-cancellation',
  PARTIAL_CANCELLATION: 'transition/partial-cancellation',
  NO_CANCELLATION: 'transition/no-cancellation',
  FULL_CANCELLATION_BY_HOST: 'transition/full-cancellation-by-host',
};

const RequestedTransitions = {
  REQUEST_PAYMENT: 'transition/request-payment',
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',
};

// Accepted Transitions
const AcceptedTransitions = {
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  COMPLETE: 'transition/complete',
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactions: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        transactions: payload,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });

const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

let allTransactions = [];

const extraParams = {
  only: 'sale',
  include: [
    'customer',
    'customer.profileImage',
    'provider',
    'provider.profileImage',
    'listing',
    'listing.currentStock',
    'booking',
    'reviews',
    'reviews.author',
    'reviews.subject',
  ],
  lastTransitions: Object.values(transitions),
};

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  const queryParams = parse(search);
  const type = queryParams?.type;

  const transitionParams = {};

  if (!type) {
    allTransactions = [];
    Object.assign(transitionParams, { lastTransitions: Object.values(AcceptedTransitions) });
  } else if (type === 'accepted') {
    allTransactions = [];
    Object.assign(transitionParams, { lastTransitions: Object.values(AcceptedTransitions) });
  } else if (type === 'requested') {
    allTransactions = [];
    Object.assign(transitionParams, { lastTransitions: Object.values(RequestedTransitions) });
  } else if (type === 'cancelled') {
    allTransactions = [];
    Object.assign(transitionParams, { lastTransitions: Object.values(CancellationTransitions) });
  }

  dispatch(fetchOrdersOrSalesRequest());

  await dispatch(fetchCurrentUser());

  const queryNextTransactions = async (page, totalPages) => {
    const newPage = page + 1;
    const firstQueryResponse = await sdk.transactions.query({
      page: newPage,
      ...extraParams,
      ...transitionParams,
    });
    const meta = firstQueryResponse?.data?.meta;
    const totalItems = meta?.totalItems;
    const firstResponse = firstQueryResponse?.data?.data || [];
    dispatch(addMarketplaceEntities(firstQueryResponse));
    allTransactions.push(...firstResponse);

    if (totalItems > 0 && totalPages !== newPage) {
      await queryNextTransactions(newPage, totalPages);
    }
  };

  const fetchTransactions = async () => {
    const firstQueryResponse = await sdk.transactions.query({
      ...extraParams,
      ...transitionParams,
    });
    const meta = firstQueryResponse?.data?.meta;
    const page = meta?.page;
    const totalPages = meta?.totalPages;
    const totalItems = meta?.totalItems;
    const firstResponse = firstQueryResponse?.data?.data || [];
    allTransactions.push(...firstResponse);
    dispatch(addMarketplaceEntities(firstQueryResponse));
    if (totalItems > 0 && totalPages !== page) {
      await queryNextTransactions(page, totalPages);
    }
  };

  await Promise.all([fetchTransactions()]);

  dispatch(fetchOrdersOrSalesSuccess(allTransactions));
};
