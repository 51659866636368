import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isLogoutInProgressSelector } from '../ducks/auth.duck';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// First create the context
const MyContext = React.createContext();

// Then create a StateHolder wrapper component
// to hold the state that the components need.
const StateHolder = props => {
  const [loginModalToggle, setLoginModalToggle] = useState(false);

  const isLogoutInProgress = useSelector(isLogoutInProgressSelector);
  const location = useLocation();
  const locationFrom = location.state?.from || null;

  useEffect(() => {
    setLoginModalToggle(false);
  }, [isLogoutInProgress]);

  useEffect(() => {
    if (locationFrom) {
      setLoginModalToggle(false);
    }
  }, [locationFrom]);

  return (
    <MyContext.Provider
      value={{
        loginModalToggle,
        setLoginModalToggle,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => useContext(MyContext);

export default StateHolder;
